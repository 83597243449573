import React from 'react';
import { FaCheck, FaSearch } from "react-icons/fa";
import { FiArrowUpRight, FiFilter } from "react-icons/fi";
import ImageLoading from './ImageLoading';
import { LuLayoutDashboard } from "react-icons/lu";
import { FaNetworkWired } from "react-icons/fa";
import Table from 'react-bootstrap/Table';
import { LiaCogSolid } from "react-icons/lia";
import { GiInfo } from "react-icons/gi";
import { MdEdit } from "react-icons/md";
import { TbClockHour8 } from "react-icons/tb";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineCog6Tooth } from "react-icons/hi2";
import { IoTrashBinOutline } from "react-icons/io5";
import { LuTrash2 } from "react-icons/lu";
import { FaRegDotCircle } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import { FaFingerprint } from "react-icons/fa";
import { GiNetworkBars } from "react-icons/gi";
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LuSearch } from "react-icons/lu";
import Translator from './I18n/Translator';


function Assistent() {


   const Users = [
      {
         name: "Pedro M...",
         status: false,
         pic: "https://media.istockphoto.com/id/1082483460/photo/beautiful-black-man.jpg?s=612x612&w=0&k=20&c=MmNFcZf6z2WLY7jMBAmtLxo6YNItudiRuzn-z7V3tZk="
      },
      {
         name: "Sara Fer...",
         status: true,
         pic: "https://img.freepik.com/free-photo/african-american-woman-smiling-mockup-psd-cheerful-expression-cl_53876-148125.jpg"
      },
      {
         name: "Carolina ...",
         status: true,
         pic: "https://img.freepik.com/free-photo/cheerful-dark-skinned-woman-smiling-broadly-rejoicing-her-victory-competition-among-young-writers-standing-isolated-against-grey-wall-people-success-youth-happiness-concept_273609-1246.jpg"
      },
      {
         name: "André Go...",
         status: true,
         pic: "https://media.istockphoto.com/id/1081381240/photo/young-smiling-african-american-man-over-white-background.jpg?s=612x612&w=0&k=20&c=T2Mq5yJ93H5jvbI87tC5RjXuGcmDdTH4GzcyOL_WRl4="
      },
      {
         name: "Pascoal Lopes",
         status: false,
         pic: "https://images.pexels.com/photos/1722198/pexels-photo-1722198.jpeg"
      },
      {
         name: "Pitter Joe",
         status: false,
         pic: "https://t3.ftcdn.net/jpg/02/22/85/16/360_F_222851624_jfoMGbJxwRi5AWGdPgXKSABMnzCQo9RN.jpg"
      },
      {
         name: "Felix Mateus",
         status: true,
         pic: "https://www.studio-pop-art.fr/cdn/shop/products/portrait-homme-465158.webp?v=1690383976&width=1445"
      },
   ];

   const Data = [
      {
         title: <Translator path="home.assistent.boxes.box1.title" />,
         description: <Translator path="home.assistent.boxes.box1.description" />,
         bg: "bg-green",
         mock: <div>
            <div className="mockbox-dash bg-section">
               <div className="filter-box">
                  <div className="flex-item">
                     <div className="item"><div className="dot bg-danger"></div><span>Ferias aprovadas</span></div>
                     <div className="item"><div className="dot bg-warning"></div><span>Ferias por aprovar</span></div>
                     <div className="item"><div className="dot bg-info"></div><span>Ferias recusadas</span></div>
                  </div>
                  <div className="flex-item">
                     <button className='btn btn-filter'><FiFilter /></button>
                  </div>
               </div>
               <div className="table-box table-vocations"> 
                 <div className="title">
                     <div className="flex-item">
                        <FaFingerprint />
                        <div className="image ml-2">
                           <ImageLoading height={30} source={Users[5].pic} />
                        </div>
                        <span>Carlos Manuel</span>
                     </div>
                  </div>
                  <hr />
                  <Table responsive size="sm">
                     <thead> 
                        <tr>
                           <th>Janeiro</th>
                           <th>Fevereiro</th>
                           <th>Março</th>
                           <th>Abril</th>
                           <th>Maio</th>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>
                              <div className="d-wrap">
                                 {Array.from(Array(30).keys()).map((item) => (
                                    <div className={`n ${(item + 1 % 2) == 2 ? "bg-warning" : ""}`}>{item + 1}</div>
                                 ))}
                              </div>
                           </td>
                           <td>
                              <div className="d-wrap">
                                 {Array.from(Array(29).keys()).map((item) => (
                                    <div className={`n ${(item + 1 % 2) == 1 ? "bg-info text-light" : ""}`}>{item + 1}</div>
                                 ))}
                              </div>
                           </td>
                           <td>
                              <div className="d-wrap">
                                 {Array.from(Array(29).keys()).map((item) => (
                                    <div className={`n ${(item + 1 % 2) == 1 ? "bg-info text-light" : ""}`}>{item + 1}</div>
                                 ))}
                              </div>
                           </td>
                           <td>
                              <div className="d-wrap">
                                 {Array.from(Array(29).keys()).map((item) => (
                                    <div className={`n ${(item + 3 % 2) == 3 ? "bg-danger text-light" : ""}`}>{item + 1}</div>
                                 ))}
                              </div>
                           </td>
                           <td>
                              <div className="d-wrap">
                                 {Array.from(Array(29).keys()).map((item) => (
                                    <div className={`n ${(item + 1 % 2) == 1 ? "bg-info text-light" : ""}`}>{item + 1}</div>
                                 ))}
                              </div>
                           </td> 
                        </tr> 
                     </tbody>
                  </Table>
               </div>
            </div>
         </div>
      },
      {
         title: <Translator path="home.assistent.boxes.box2.title" />,
         description: <Translator path="home.assistent.boxes.box2.description" />,
         mock: <div className="mockbox-dash bg-section">
            <div className="filter-box">
               <input type="search" placeholder='Pesquisar...' className="form-control" />
               <button className='btn'><LuSearch size={20} /></button>
               <button className='btn btn-filter'><FiFilter /></button>
            </div>
            <div className="table-box users-table">
               <Table size="sm">
                  <thead>
                     <tr>
                        <th><div className="icon"><FaRegDotCircle /></div></th>
                        <th>Colaborador</th>
                        <th>Estado</th>
                        <th>Ações</th>
                     </tr>
                  </thead>
                  <tbody>
                     {Users.map((item, index) => (
                        <tr>
                           <td><div className="icon mt-1"><FaRegCircle /></div></td>
                           <td>
                              <div className="flex-item">
                                 <div className="pic">
                                    <ImageLoading source={item.pic} />
                                 </div>
                                 <span>{item.name}</span>
                              </div>
                           </td>
                           <td>
                              <div className="flex-item mt-2">
                                 <Form.Check type="switch" checked={item.status} id="custom-switch" />
                                 <span>{item.status === false ? " Inactivo" : " Activo"}</span>
                              </div>
                           </td>
                           <td>
                              <div className="flex-item">
                                 <div className="icon"><FiEdit3 /></div>
                                 <div className="icon"><LuTrash2 /></div>
                              </div>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
            </div>
         </div>
      },
   ];


   return (
      <section className=" assistent-section">
         <div className="wrapper">
            <div className="content">
               <div className="title-items">
                  <h1 className="title-2"><Translator path="home.assistent.title" /> </h1>
                  <p><Translator path="home.assistent.description" /></p>
               </div>
               <div className="wrap-items">
                  {Data.map((item, index) => (
                     <article className={`card-${index + 1} ${item.class}`} >
                        <div className="dets">
                           <h1 className="title-4">{index + 1}. {item.title}</h1>
                           <p className="description-1"> {item.description}</p>
                        </div>
                        <div className={`outer-border outer-${index + 1}`}>
                           <div className="btns-container">
                              <div className=" left btns">
                                 <div className="bt bt1"></div>
                                 <div className="bt bt-2"></div>
                                 <div className="bt bt3"></div>
                              </div>
                              <div className="btns right">
                                 <div className="bt bt4"> </div>
                              </div>
                           </div>
                           <div className={`image-box imgbox-${index + 1}`}>
                              <div className="cam-container">
                                 <div className="cam"></div>
                              </div>
                              <div className="camera-area-box">
                                 <div className="bx">
                                    <div className="speaker"></div>
                                    <div className="icon"></div>
                                 </div>
                              </div>
                              {item.mock}
                           </div>
                        </div>
                        <div className="button-container">
                           <Link to="/request_budget">
                              <button className="btn bg-main"><Translator path="home.assistent.button" /></button>
                           </Link>
                        </div>
                     </article>
                  ))}
               </div>
            </div>
         </div>
      </section>
   )
}

export default Assistent
