import React from 'react'
import Slider from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ImageLoading from '../ImageLoading';
import Translator from '../I18n/Translator';




 

function TestimonialVideos() {

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

 
  const responsive1 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };


  const Data = [
    {
      video: "https://www.youtube.com/embed/Jw7s42Op2ao?si=CBzdGPWdZckquMvQ", // embed youtube url video from embed options
      name: "Pedro Lopes",
      charge: "Engenheiro de software na",
      company: "Olisipo",
      thumb:"https://i3.ytimg.com/vi/Jw7s42Op2ao/maxresdefault.jpg"
    },
    {
      video: "https://www.youtube.com/embed/8LSt8_11wbQ?si=Cxa3XD2mFQ7bjugw", // embed youtube url video from embed options
      name: "Sandra Manuel",
      charge: "Comercial Senior na",
      company: "rumos",
      thumb:"https://i3.ytimg.com/vi/8LSt8_11wbQ/maxresdefault.jpg"
    },
    {
      video: "https://www.youtube.com/embed/O9izUL_fc9I?si=6_Nkzv2Z-GZvZPU2", // embed youtube url video from embed options
      name: "Fernando Garcia",
      charge: "Diretor de recursos Humanos na ",
      company: "Cisco",
      thumb:"https://i3.ytimg.com/vi/O9izUL_fc9I/maxresdefault.jpg"
    },
    {
      video: "https://www.youtube.com/embed/KNG-OqNe5PU?si=M471fHkXCu5Bj69B", // embed youtube url video from embed options
      name: "Clarice borges",
      charge: "Diretor de recursos Humanos na ",
      company: "Drivers",
      thumb:"https://i3.ytimg.com/vi/KNG-OqNe5PU/maxresdefault.jpg"
    },

    {
      video: "https://www.youtube.com/embed/Q_rS97esv-M?si=uIw-IWjhFA4LR_V4", // embed youtube url video from embed options
      name: "Santiago Mateus",
      charge: "Promotor de eventos",
      company: "Drivers",
      thumb:"https://i3.ytimg.com/vi/Q_rS97esv-M/maxresdefault.jpg"
    },
  ];

  

  const CustomDot = ({ onClick, ...rest }) => {
    const {
      onMove,
      index,
      active,
      carouselState: { currentSlide, deviceType }
    } = rest;

    console.log(rest);
    const carouselItems = [1, 2, 3]; 
    return ( 
    <li className={`video-test-box  ${active ? "active" : "inactive"}`} onClick={() => onClick()}   > 
          <ImageLoading height={150} source={Data[index].thumb} />
      </li> 
    );
  };
  

  return (
    <div className='testimonials-video'>
      <div className="wrapper">
        <div className="content">
          <div className="center">
            <h1 className="title-2"><Translator path="testimonials.title" /></h1>
            <p className='description-1'>#<Translator path="testimonials.subtitle" /></p>
          </div>
          <section>
            <div className="slider-container">
              <Slider showDots customDot={<CustomDot />} arrows={true} swipeable={true} draggable={false}
               infinite={true} responsive={responsive}>
                {Data.map((item, index) => (
                  <article>
                    <div className="video-test-box">
                      <div className="text-box">
                        <div><h3>{item.name}</h3></div>
                        <div><span>{item.charge}<span>#{item.company}</span></span></div>
                      </div>
                      <div className="video-box">
                        <div className="frame-zone">
                          <iframe src={item.video} title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <br />
                       </div>
                    </div>
                  </article>))}
              </Slider>  
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default TestimonialVideos
