import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/Home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contacts from './Pages/Contacts';
import NotFounded from './Pages/NotFounded';
import Testimonials from './Pages/Testimonials';
import RequestBudget from './Pages/RequestBudget';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contacts' element={<Contacts />} />
        <Route path='/successfuly_stories' element={<Testimonials />} />
        <Route path='/request_budget' element={<RequestBudget />} />
        <Route path='*' element={<NotFounded />} />
      </Routes>
    </Router>
  );
}

export default App;