import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import Header from '../Components/Header'
import CustomersSection from '../Components/CustomersSection'
import Assistent from '../Components/Assistent';
import IntegrationSection from '../Components/IntegrationSection';
import PerformanceSection from '../Components/PerformanceSection';
import TestimonialsSection from '../Components/TestimonialsSection';
import FactsSection from '../Components/FactsSection';
import PriceSection from '../Components/PriceSection';
import FooterSection from '../Components/FooterSection';
import FeaturesSection from '../Components/FeaturesSection';
import ScrollToTop from '../Components/ScrollToTop';
import ModulesSection from '../Components/ModulesSection';
import HomeModules from '../Components/HomeModules';
import { useAnimate } from 'framer-motion';

function Home() {
  document.title = "2smart";
  const [BoxHeight, setBoxHeight] = useState(0);

  function getHeight() { 
    if(document.querySelectorAll("#bx").length > 0 && document.querySelectorAll(".point-2").length > 0){  
      let point1 = document.getElementById("bx");
      let point2 = document.getElementById("point-2");
        if (Math.floor(point2.offsetTop * 1) > 0 && Math.floor(point2.offsetTop * 1) !== undefined) {
          let dif = Math.floor(point2.offsetTop + 60) - Math.floor(point1.offsetTop + 60);
          point1.style.height = dif + 90 + "px";
          setBoxHeight(Math.floor(point2.offsetTop + 90));
        }
      } 
  }


  setInterval(() => {
    getHeight();
  }, 1000);


  return (
    <div>
      <ScrollToTop />
      <div className="point bg-danger point-1"></div>
      <div className="full-flex">
        <div className="wp bg-primary" style={{ width: 0, height: BoxHeight + "px" }} ></div>
        <div style={{ width: "100%" }} >
          <Navbar active={1} />
          <Header />
        </div>
      </div>
      <FeaturesSection />
      <Assistent />
      <IntegrationSection />
      <PerformanceSection />
      <FactsSection />
      <HomeModules />
      <FooterSection />
    </div>
  )
}

export default Home
