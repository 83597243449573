import React, { Fragment, useState } from "react";
import { useAlert } from "react-alert";
import Navbar from '../Components/Navbar';
import FooterSection from '../Components/FooterSection';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ImageLoading from '../Components/ImageLoading';
import SendEmail from "../Components/SendEmail"; 
import axios from "axios";
import Translator from "../Components/I18n/Translator";

 
function RequestBudget() {
    document.title = "Solicitar orçamento";
    const [ClientName, setClientName] = useState(null);
    const [ClientEmail, setClientEmail] = useState(null);
    const [ClientCompany, setClientCompany] = useState(null);
    const [ClientSector, setClientSector] = useState(null);
    const [ClientNif, setClientNif] = useState(null);
    const [ClientMessage, setClientMessage] = useState(null);
    const [ClientPhone, setClientPhone] = useState(null); 
    const [ClientTotalCustomers, setClientClientTotalCustomers] = useState(null); 
    const [ClientCheckTerms, setClientCheckTerms] = useState(false); 
    const alert = useAlert();

    function Success(){
       alert.success("Mensagem enviada !");
       let inpu = document.querySelectorAll("input");
       let text = document.querySelectorAll("textarea");
       for (let i = 0; i < inpu.length; i++) inpu[i].value = null;
       for (let i = 0; i < inpu.length; i++) inpu[i].checked = null;
       for (let i = 0; i < text.length; i++) text[i].value = null;
    }

     function SubmitForm(e){ 
          try {
            e.preventDefault();  
            if (ClientPhone*1 > 0){
                const Data =  {nif:ClientNif, name:ClientName, company:ClientCompany, sector:ClientSector, 
                email:ClientEmail, phone:ClientPhone, employeesnumber:ClientTotalCustomers, message:ClientMessage} 
                axios.post("http://localhost:5000/sendemailaddressmessagetocompany", Data).then((response) => 
                     Success()
                ).catch((error) => console.log(error) );  //alert.error("Erro ao enviar a mensagem !")
            } else{
                alert.error("Preencha corretamente os campos !");      
            } 
          } catch (error) {
            console.log(error);
            alert.error("Erro ao enviar mensagem !"); 
          } 
     } 

    return (
        <Fragment> 
            <div className='request-budget'>
                <Navbar active={8} black />
                <div className="wrapper">
                    <div className="title-area">
                        <h1 className="title-1">
                            <Translator path="requestbudget.title" />
                        </h1>
                    </div>
                </div>
                <div className="form-zone">
                    <div className="wrapper">
                        <div className="content">
                            <div className="images-area">
                                <div className="image">
                                    <ImageLoading source={"https://images.pexels.com/photos/16282306/pexels-photo-16282306/free-photo-of-a-person-using-a-calculator.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"} />
                                </div>
                            </div>
                            <div className="form-area">
                                <div className="form-box">
                                    <Form onSubmit={SubmitForm}>
                                        <div className="center">
                                            <h1 className="title-3"> <Translator path="requestbudget.description1" /> </h1><br />
                                            <small className='description-1'>
                                                <Translator path="requestbudget.description2" />
                                            </small>
                                        </div>
                                        <div className="box-inputs mt-3">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label><Translator path="requestbudget.label1" /> </Form.Label>
                                                <Form.Control  required onChange={(e)=>setClientName(e.target.value)} onPaste={(e)=>setClientName(e.target.value)}  type="text" placeholder="..." />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label><Translator path="requestbudget.label2" /></Form.Label>
                                                <Form.Control required onChange={(e)=>setClientEmail(e.target.value)} onPaste={(e)=>setClientEmail(e.target.value)}  type="email" placeholder="..." />
                                            </Form.Group>
                                        </div>
                                        <div className="box-inputs mt-3">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label><Translator path="requestbudget.label3" /></Form.Label>
                                                <Form.Control required onChange={(e)=>setClientCompany(e.target.value)} onPaste={(e)=>setClientCompany(e.target.value)}  type="text" placeholder="..." />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label><Translator path="requestbudget.label4" /></Form.Label>
                                                <Form.Control required onChange={(e)=>setClientPhone(e.target.value)} onPaste={(e)=>setClientPhone(e.target.value)}  type="text" placeholder="..." />
                                            </Form.Group> 
                                        </div>
                                        <div className="box-inputs col mt-3">
                                            <Form.Group className="mb-3 col" controlId="formBasicEmail">
                                                <Form.Label><Translator path="requestbudget.label0" /></Form.Label>
                                                <Form.Control  required type="number" onChange={(e)=>setClientClientTotalCustomers(e.target.value)}
                                                 onPaste={(e)=>setClientClientTotalCustomers(e.target.value)}   placeholder="..." /> 
                                            </Form.Group> 
                                        </div>
                                        <div className="box-inputs mt-3">
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Nif</Form.Label>
                                                <Form.Control required onChange={(e)=>setClientNif(e.target.value)} onPaste={(e)=>setClientNif(e.target.value)}
                                                type="number" placeholder="..." />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label><Translator path="requestbudget.label5" /></Form.Label>
                                                <Form.Select required onChange={(e)=>setClientSector(e.target.value)}
                                                 onPaste={(e)=>setClientSector(e.target.value)} > 
                                                    <option value="Agroalimentar">Agroalimentar</option>
                                                    <option value="Comércio">Comércio</option>
                                                    <option value="Construção">Construção</option>
                                                    <option value="Consultoria">Consultoria</option>
                                                    <option value="Contabilidade">Contabilidade</option>
                                                    <option value="Educação">Educação</option>
                                                    <option value="Energia">Energia</option>
                                                    <option value="Hotelaria">Hotelaria</option>
                                                    <option value="Imobiliário">Imobiliário</option>
                                                    <option value="Indústria">Indústria</option>
                                                    <option value="Logística/Distribuição">Logística/Distribuição</option>
                                                    <option value="Marketing/Publicidade">Marketing/Publicidade</option>
                                                    <option value="Notariado">Notariado</option>
                                                    <option value="Saúde e Estética">Saúde e Estética</option>
                                                    <option value="Setor Público">Setor Público</option>
                                                    <option value="Tecnologia">Tecnologia</option>
                                                    <option value="Telecomunicações">Telecomunicações</option>
                                                    <option value="Turismo e Lazer">Turismo e Lazer</option>
                                                    <option value="Outro">Outro</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </div>
                                        <div className="flex-item">
                                            <Form.Check required onChange={(e)=>setClientCheckTerms(e.target.value)} type={"checkbox"} id={`default-1`} label={<Translator path="requestbudget.link_label" />} />
                                            <a href="https://exportech.com.pt/privacy_and_policy" target='_blank' ><strong><Translator path="requestbudget.link_text" /></strong></a>
                                        </div>
                                        <Form.Group className="mb-3 mt-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label><Translator path="requestbudget.label6" /></Form.Label>
                                            <Form.Control onChange={(e)=>setClientMessage(e.target.value)} onPaste={(e)=>setClientMessage(e.target.value)}  placeholder='...' as="textarea" rows={6} />
                                        </Form.Group>
                                        <button className="btn bg-main text-light"><Translator path="requestbudget.button" /></button>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br /><br />
                <FooterSection />
            </div>
        </Fragment> 
    )
}

export default RequestBudget


