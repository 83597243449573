import React from 'react'
import { FcElectronics } from "react-icons/fc";
import ImageLoading from './ImageLoading';
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { LiaCogSolid } from "react-icons/lia";
import { LuCalendarDays } from "react-icons/lu";
import { TiChartPieOutline } from "react-icons/ti";
import { CgMenuMotion } from "react-icons/cg";
import { HiOutlineDocumentChartBar } from "react-icons/hi2";
import { GiProgression } from "react-icons/gi";
import Table from 'react-bootstrap/Table';
import { TbCertificate } from "react-icons/tb";
import Translator from './I18n/Translator';


function IntegrationSection() {
  
  const Data = [
    {
      icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTl2NGkCJQjMwnZ55Hc_kRZXbYVfemJpxjVeQ&s",
      title: <Translator path="home.integration.softwares.s13.title" />,
      label: <Translator path="home.integration.softwares.s13.label" />
    },
    {
      icon: "https://innux.pt/wp-content/uploads/2014/01/-artsoftbusinesssoftware-horizontal-500x168.png?500x168 ",
      title: <Translator path="home.integration.softwares.s2.title" />,
      label: <Translator path="home.integration.softwares.s2.label" />
    },
    {
      icon: "https://www.calltrackingmetrics.com/wp-content/uploads/2019/02/DYNAMICS.png",
      title: <Translator path="home.integration.softwares.s3.title" />,
      label: <Translator path="home.integration.softwares.s3.label" />
    },
    {
      icon: "https://gestware.pt/wp-content/uploads/2016/07/logo-gestware.png",
      title: <Translator path="home.integration.softwares.s4.title" />,
      label: <Translator path="home.integration.softwares.s4.label" />
    },
    {
      icon: "https://upload.wikimedia.org/wikipedia/commons/b/b3/Sage_logo.png",
      title: <Translator path="home.integration.softwares.s5.title" />,
      label: <Translator path="home.integration.softwares.s5.label" />
    },
    {
      icon: "https://storage.landing.jobs/zzDDnXr5fSBoM6a3iwiUS9te ",
      title: <Translator path="home.integration.softwares.s6.title" />,
      label: <Translator path="home.integration.softwares.s6.label" />
    },
    {
      icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/SAP_2011_logo.svg/2560px-SAP_2011_logo.svg.png ",
      title: <Translator path="home.integration.softwares.s7.title" />,
      label: <Translator path="home.integration.softwares.s7.label" />
    },

    {
      icon: "https://www.himss.org/sites/hde/files/styles/f2f_sponsor_full_logo_xs_sm/public/media/image/2023/05/24/f3m.png?itok=aKdKEhf4",
      title: <Translator path="home.integration.softwares.s8.title" />,
      label: <Translator path="home.integration.softwares.s8.label" />
    },
    {
      icon: "https://www.solutions-ressources-humaines.com/logo/d3f2f8db09aec60logo_meta4_sans_baseline.jpg",
      title: <Translator path="home.integration.softwares.s9.title" />,
      label: <Translator path="home.integration.softwares.s9.label" />
    },
    {
      icon: "https://cdn.prod.website-files.com/6342ebadeb3b664be3039230/6567532a2b6dbe4beb59a8cb_PHC%20(logo).png",
      title: <Translator path="home.integration.softwares.s10.title" />,
      label: <Translator path="home.integration.softwares.s10.label" />
    },
    {
      icon: "https://pq-media.imgix.net/l/312dede68873e3ece83457fde0a22390.jpg?w=200&auto=compress&fit=fill&fill=solid&fill-color=ffffff&h=200",
      title: <Translator path="home.integration.softwares.s11.title" />,
      label: <Translator path="home.integration.softwares.s11.label" />
    },
    {
      icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKcmlNJkmvsfoztdPsUBGEUeKls-XRXYdxCw&s ",
      title: <Translator path="home.integration.softwares.s12.title" />,
      label: <Translator path="home.integration.softwares.s12.label" />
    },
    {
      icon: "https://innux.pt/wp-content/uploads/2014/01/-eticadata-logo-500x238.png?500x238",
      title: <Translator path="home.integration.softwares.s1.title" />,
      label: <Translator path="home.integration.softwares.s1.label" />
    },
    



    {
      icon: "https://innux.pt/wp-content/uploads/2014/01/-airc-fundobranco-logocorcoorporativa-500x195.png?500x195",
      title: <Translator path="home.integration.softwares.s14.title" />,
      label: <Translator path="home.integration.softwares.s14.label" />
    },
  ];

  return (
    <section className='integration-section'>
      <div className="wrapper">
        <div className="content">
          <div className="center">
            <h1 className="title-2"><Translator path="home.integration.title" /></h1>
            <p className="description-1"><Translator path="home.integration.description1" /></p>
            <p className="description-1" style={{marginTop:"10px"}} ><Translator path="home.integration.description2" /></p>
          </div>
          <div className="content-area">
            <aside className="animated-tree"> 
              <div className="rotate-items">
                {Data.map((item, index) => (
                  <div className={`item item-${index + 1}`} key={index + 1}>
                    <ImageLoading height={70} source={item.icon} alt="" />
                  </div>
                ))}
              </div>
              <aside className="mobile-frame">
                <div class='mobile-phone'>

                  <div className="btns-container">
                    <div className=" left btns">
                      <div className="bt bt1"></div>
                      <div className="bt bt-2"></div>
                      <div className="bt bt3"></div>
                    </div>
                    <div className="btns right">
                      <div className="bt bt4"></div>
                    </div>
                  </div>
 
                  <div class='brove'>
                    <div className="camera"></div>
                    <span class='speaker'></span>
                  </div>
                  <div class='screen'>
                    <div className="screen-page screen-dashboard">
                      <aside className="main-box">
                        <div className="header-dash">
                          <div className="space-items">
                            <div className="logo">
                              <ImageLoading height={45} source={require("../Assets/Images/logo2.png")} />
                            </div>
                            <div className="profile-itens">
                              <div className="icon"><CgMenuMotion /></div>
                              <div className="icon"><LiaCogSolid /></div>
                              <div className="avatar"><ImageLoading source="https://www.man.com/sites/default/files/uploads/content/team/Large_Barry-Fitzgerald2.jpg" height={45} /></div>
                            </div>
                          </div>
                          <div className="page-boxes">
                            <div className="col-lg-12">
                              <h5 className='text-light'>Bem-Vindo Marcelo </h5>
                            </div>
                            <div className="box box-orange">
                              <div className="chart-box">
                                <div className="status">45%</div>
                                <div class="arc"></div>
                              </div>
                              <div className="flex-item"><div className="count">12</div>  Extras</div>
                            </div>
                            <div className="box box-green">
                              <div className="chart-box">
                                <div className="status">15%</div>
                                <div class="arc"></div>
                              </div>
                              <div className="flex-item"><div className="count">12</div>  Faltas Parciais</div>
                            </div>
                            <div className="box box-teal">
                              <div className="chart-box">
                                <div className="status">34%</div>
                                <div class="arc"></div>
                              </div>
                              <div className="flex-item"><div className="count">12</div>  Ausências</div>
                            </div>
                            <div className="box box-red">
                              <div className="chart-box">
                                <div className="status">87%</div>
                                <div class="arc"></div>
                              </div>
                              <div className="flex-item"><div className="count">12</div>  Baixas</div>
                            </div>
                          </div>
                          <br /><br /><br /><br />
                          <br /><br /> <br />
                          <div className="box box-lg mt-2">
                            <div className="title-item"><TbCertificate /> licenciamento</div>
                            <Table size="lg" >
                              <tbody>
                                <tr>
                                  <td className='flex-item'> <strong>30</strong> Licenças</td>
                                </tr>
                                <tr>
                                  <td className='flex-item'><strong>27 Activas</strong></td>
                                </tr>
                                <tr>
                                  <td className='flex-item'><strong>01</strong> Histórico</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        <div className="menu-nav">
                          <div className='active'>
                            <LuCalendarDays />
                          </div>
                          <div>
                            <HiOutlineDocumentChartBar />
                          </div>
                          <div className='main-icon'>
                            <TiChartPieOutline />
                          </div>
                          <div>
                            <GiProgression />
                          </div>
                          <div>
                            <LuCalendarDays />
                          </div>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
              </aside>

            </aside>
            <div className="text-area">
              <ul>
                {Data.map((item, index) => (
                  <li>
                    <div className="icon">
                      <IoIosCheckmarkCircleOutline />
                      <strong>{item.title}</strong>
                    </div>
                    <span> <strong> - </strong>{item.label}</span>
                  </li>
                ))}
              </ul>
             <br />
             <div className="mt-4">
             <strong className="description-2 mt-4">Não encontra o seu ? 
              <span className="text-main"> Não se preocupe, nós integramos !</span> </strong>
             </div>
              <div className="buttons">
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <button>
                    <ImageLoading height={40}
                      source={"https://texttofloss.com/wp-content/uploads/2021/01/Google-Play-Store-Button.png"} alt="" />
                  </button>
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <button>
                    <ImageLoading height={40}
                      source={"https://www.netsupportmanager.com/wp-content/uploads/2019/06/en-1.png"} alt="" />
                  </button>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default IntegrationSection
