import React, { useEffect } from 'react';
import { FaFacebook } from "react-icons/fa";
import { SiLinkedin } from "react-icons/si";
import { IoMailUnread } from "react-icons/io5";
import { MdPhoneInTalk } from "react-icons/md";
import { BiArrowToRight } from "react-icons/bi";
import ImageLoading from './ImageLoading';
import { Link } from 'react-router-dom';
import Translator from './I18n/Translator';


function FooterSection() {





   function topFunction() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    useEffect(()=>(
      topFunction()
    ),[]);

   return (
      <section className="bg-black footer">
         <div className="wrapper">
            <div className="center-info">
                <div className="text-center"> 
                   <h1 className="title-2"><Translator path="home.footer.text1" /></h1>
                   <span className="text-silver">#<Translator path="home.footer.text0" /></span>
                </div>
                <Link to="/request_budget">
                   <button className="btn bg-main text-white"><Translator path="home.footer.text2" /></button>
                </Link>
            </div>
            <div className="footer-main-content">
               <div className="item about-item">
               <Link to="/request_budget">
                  <ImageLoading height={60} source="https://cassianos.2smart.pt/images/logo/login-hrm.svg" alt="" />               
               </Link>
                  <p className='text-light'><Translator path="home.footer.description" /></p>
                  <div className="social-icons"> 
                     <Link to="#" target="_blank" rel="noopener noreferrer"><FaFacebook /></Link>
                     <Link to="#" target="_blank" rel="noopener noreferrer"><SiLinkedin /></Link>
                     <Link to="mailto:helpdesk@exportech.com.pt"><IoMailUnread /></Link> 
                     <Link to="tel:+351291601603"><MdPhoneInTalk /></Link>
                  </div>
               </div>
               <div className="item links">
                  <h4><Translator path="home.footer.text3" /></h4>
                  <ul> 
                     <li><Link to="https://exportech.com.pt/about" target="_blank" rel="noopener noreferrer">Exportech Portugal</Link></li>
                     <li><Link to="https://exportech.com.pt/products" target="_blank" rel="noopener noreferrer"><Translator path="home.footer.link1" /></Link></li>
                     <li><Link to="https://exportech.com.pt/contacts" target="_blank" rel="noopener noreferrer"><Translator path="home.footer.link2" /></Link></li> 
                  </ul>
               </div>
               <div className="item links">
                  <h4><Translator path="home.footer.text4" /></h4>
                  <ul>  
                     <li><Link to="/request_budget"><Translator path="home.footer.text5" /></Link></li>
                     <li><Link to="/contacts"><Translator path="home.footer.text6" /></Link></li>
                     <li><Link to="/successfuly_stories"><Translator path="home.footer.text7" /></Link></li>
                     <li>
                     <Link to="https://www.livroreclamacoes.pt/Inicio/"   target="_blank" rel="noopener noreferrer">
                           <ImageLoading  source="https://www.gocarmat.pt/wp-content/uploads/lreclamacoes_gcmt-2.png" height={50}/>
                     </Link> 
                  </li>
                  </ul>
               </div>
            </div>
            <div className="space-items">
               <div>
                  <p>Copyright 2024 2Smart | <Translator path="home.footer.text8" />
                     <Link to="https://exportech.com.pt"  target="_blank"
                      rel="noopener noreferrer"> Exportech Portugal</Link>
                  </p>
               </div>
              <div>
                 <BiArrowToRight />
                 <p> 
                   <Translator path="home.footer.text9" />, 
                   <Link to="https://exportech.com.pt/privacy_and_policy" target="_blank"
                   rel="noopener noreferrer"><Translator path="home.footer.text10" /></Link>
               </p>
              </div>
            </div>
         </div>
      </section>
   )
}

export default FooterSection

 
 