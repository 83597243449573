import React from 'react'
import { TbHeartRateMonitor } from "react-icons/tb";
import { FiArrowUpRight } from "react-icons/fi";
import ImageLoading from './ImageLoading';
import { AiOutlineDashboard } from "react-icons/ai";
import { GrGroup } from "react-icons/gr";
import { PiLightbulbFilamentLight } from "react-icons/pi";
import { LuBadgeX } from "react-icons/lu";
import { MdOutlineTimer } from "react-icons/md";
import { RiFlashlightLine } from "react-icons/ri";
import { TbReport } from "react-icons/tb";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { PiLightbulbFilamentBold } from "react-icons/pi";
import { Link } from 'react-router-dom';
import Translator from './I18n/Translator';

function FeaturesSection() {

   const Data = [

      {
         icon:require("../Assets/Images/icons/6.png"),
         title: <Translator path="home.features.box1.title" />,
         desciption:<Translator path="home.features.box1.description" />
      },
      {
         icon: require("../Assets/Images/icons/9.png"),
         title:<Translator path="home.features.box2.title" />,
         desciption:<Translator path="home.features.box2.description" />
      },
      {
         icon:require("../Assets/Images/icons/8.8.png"),
         title:<Translator path="home.features.box3.title" />,
         desciption:<Translator path="home.features.box3.description" />
      },
      {
         icon: require("../Assets/Images/icons/3.3.png"),
         title:<Translator path="home.features.box4.title" />,
         desciption: <Translator path="home.features.box4.description" />
      },
      {
         icon: require("../Assets/Images/icons/7.7.png"),
         title:<Translator path="home.features.box5.title" />,
         desciption: <Translator path="home.features.box5.description" />
      },
      {
         icon: require("../Assets/Images/icons/2.png"),
         title:<Translator path="home.features.box6.title" />,
         desciption: <Translator path="home.features.box6.description" />
      },
      {
         icon:require("../Assets/Images/icons/1.1.png"),
         title:<Translator path="home.features.box7.title" />,
         desciption:<Translator path="home.features.box7.description" />
      },
      {
         icon:require("../Assets/Images/icons/10.10.png"),
         title:<Translator path="home.features.box8.title" />,
         desciption: <Translator path="home.features.box8.description" />
      },
   ];


   return (
      <section className='key-features'>
           <div className="point point-2 bg-danger"></div>
         <div className="wrapper">
            <div className="content">
               <div className="center">
                  <h1 className="title-2"><Translator path="home.features.title" /></h1>
                  <p className='description-1 mt-3'><Translator path="home.features.description" /></p>
               </div> 
               <div className="wrap-items">  
                  {Data.map((item, index) => (
                     <div className={`box box-${index + 1}`} key={index}>
                        <article key={index}>
                           <div className='dets tt'><strong className='title-3'>{item.title}</strong></div>
                           <div className="icon-content">
                              <div className="icon-container">
                                 <div className="icon">
                                   <ImageLoading height={30} source={item.icon} alt={item.title} />
                                 </div>
                              </div>
                           </div>
                           <div className="dets">
                              <p className="description-1">{item.desciption}</p>
                           </div>
                        </article>
                     </div>
                  ))}
               </div>
            </div>
            <div className="center">
               <div className="contact-link">
                  <p className="description-1"><Translator path="home.features.link_label" /> </p>
                  <Link to="/contacts"><strong><Translator path="home.features.link_text" /> <FiArrowUpRight /></strong> </Link>
               </div>
            </div>
         </div>
      </section>
   )
}

export default FeaturesSection
