import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { GrLanguage } from "react-icons/gr";
import Translator from './Translator';

 

const I18n = () => {
  const { t, i18n } = useTranslation();

  function handleChangeLanguage(language) {   
    i18n.changeLanguage(language);
    localStorage.setItem("language", language); 
  }
  const selectedLanguage = i18n.language
  const storedLang = localStorage.getItem("language");

  const LanguageSt = () => {  
    if(storedLang === null){
      i18n.changeLanguage("PT");
      localStorage.setItem("language", "PT"); 
    } else {
       i18n.changeLanguage(storedLang);
    } 
  }

  useEffect(() => {
    LanguageSt();
    setTimeout(() => {
      LanguageSt();
    }, 100);
  }, []);



  return (
    <div className="toggle-language">
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          <div className="icon"><GrLanguage /> {storedLang} </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <div onClick={() => handleChangeLanguage('PT')}>
            <Dropdown.Item href="#" className={selectedLanguage === "PT" ? "active text-light" : ""}>
              <div className='flex-tag'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Flag_of_Portugal.svg/640px-Flag_of_Portugal.svg.png" /> 
                  <span className={selectedLanguage === "PT" ? "text-light" : ""}><Translator path="navbar.lang1" /></span>
              </div>
            </Dropdown.Item>
          </div>
          <div onClick={() => handleChangeLanguage('EN')}>
            <Dropdown.Item href="#" className={selectedLanguage === "EN" ? "active" : ""}>
              <div className='flex-tag'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/United-kingdom_flag_icon_round.svg/2048px-United-kingdom_flag_icon_round.svg.png" />
                 <span className={selectedLanguage === "EN" ? "text-light" : ""}><Translator path="navbar.lang2" /></span>
            </div>
            </Dropdown.Item>
          </div>
          <div onClick={() => handleChangeLanguage('ES')}>
            <Dropdown.Item href="#" className={selectedLanguage === "ES" ? "active" : ""}>
              <div className='flex-tag'><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/89/Bandera_de_Espa%C3%B1a.svg/1200px-Bandera_de_Espa%C3%B1a.svg.png" />
                 <span className={selectedLanguage === "ES" ? "text-light" : ""}><Translator path="navbar.lang3" /></span>
             </div>
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default I18n
