
// { }


const PORTUGUESE = {
  translations:{
    navbar:{
      link1:'Inicio',
      link2:`Casos de sucesso`,
      link3:`Contactos`,
      link4:`Solicitar Orçamento`,
      lang1:`Português`,
      lang2:`Inglês`,
      lang3:`Espanhol`,
    }, 
    home:{
      header:{
        small:`We're Smarter Together!`,
        title1:`2Smart Human Resources Management`,
        title2:`Plataforma Inteligente de Gestão de Assiduidades Cloud`,
        description1:`A Plataforma Cloud com Portal de Colaborador integrada e dotada de IA que o ajuda a gerir eficientemente os seus RH.`,
        description2:`* Gestão de recursos humanos sem a necessidade de usar o papel ou softwares externos`, 
        button1:`Conhecer Mais`,
        button2:`Assistir ao Video`
      },
      clients_description:`Junte-se ao Universo de mais de 600 clientes que já utilizam o 2Smart HRM Cloud `,
      features:{
        title:`Funcionalidades Únicas`,
        description:`O 2Smart HRM é uma plataforma intuitiva desenhada  para simplificar os
        processos associados à gestão de RH , mantendo total conformidade legal.`,
        link_label:`Tem alguma dúvida?`,
        link_text:`Entre em contato agora`,
        box1:{
          title:"Dashboard",
          description:`Interface atrativo e simplificado, segmentado por perfil de acesso e contendo as métricas mais importantes  incluindo alertas.`
        },
        box2:{
          title:"Módulos",
          description:`O 2Smart HRM dispõe de 24 Módulos opcionais que permitem aos Gestores de RH 
          usufruirem de ferramentas essenciais para a Gestão da sua empresa - Venha conhecê-los em detalhe!`
        },
        box3:{
          title:"Planificação",
          description: `Sejam Escalas, Turnos complexos, Horários fixos com objectivos de 
          efetivo diferentes ou mesmo Isenção de Horário, o 2Smart HRM dispõe de solução permitindo-lhe responder a cada uma das suas necessidades..
           e tudo de forma simplificada. Desafie-nos, queremos conhecer as suas necessidades!`
        },
        box4:{
          title:"Análise de Produtividade",
          description:`O 2Smart HRM adoptou uma visão totalmente diferente de tudo o que existe - venha conhecer o nosso interface de análise 
          de produtividade repleto de métricas, filtros e alertas que o ajudam a focar-se no
          mais importante poupando imenso tempo em processos desnecessários.`
        },
        box5:{
          title:"Relatórios",
          description:`Inúmeros Relatórios incluídos na versão base do 2Smart HRM concebidos de forma ponderada para que o ajudem verdadeiramente
         a analisar o que importa e manter conformidade legal. Possibilita ainda o agendamento para envio automático de relatórios para as suas Chefias, RH ou Colaboradores nas datas ou 
        dias da semana que assim entender ou em situações específicas devidamente pré-configuradas. `
        },
        box6:{
          title:"Conformidade",
          description:`O 2Smart Human Resouces Management está em conformidade com o Código de Trabalho e com a normativa europeia do RGPD. 
          Assegure total conformidade em caso de fiscalização e emita os Relatórios solicitados em segundos evitando coimas desnecessárias.`
        },
        box7:{
          title:"Ferramentas",
          description:`Seja Banco de Horas, Gestão de Férias, Classificação de Absenteísmo ou Gestão de Horas Suplementares diurnas ou nocturnas, 
        o 2Smart HRM dá-lhe a resposta que necessita de forma simples e automatizada.`
        },
        box8:{
          title:"Onboarding",
          description:`O 2Smart HRM ajuda-o a no processo de Onboarding - Venha conhecer o processo inovador que 
          implementámos e surpreenda-se com os resultados na satisfação dos seus colaboradores!`
        }
      },
      assistent:{
        title:`2Smart -  Power to You!`,
        description:`Venha conhecer como o Módulo de Gestão de Tarefas e Equipas  poderão   ajudá-lo de forma inovadora na gestão da sua empresa permitindo 
         um controlo mais eficaz, delegação de competências mais simples e ainda uma série de métricas que o ajudam a reduzir significativamente os seus custos.`,
        button:`Compre Agora`,
        boxes:{
          box1:{
            title:`Métricas de assiduidades`,
            description:`A 2Smart App permite o Acesso ao seu Portal do Colaborador de forma simples e muito intuitiva - Justifique o seu Absenteísmo, 
            consulte as suas métricas de assiduidades ou planeie as suas férias em qualquer lugar.`
          },
          box2:{
            title:`Gestão  de Equipas`,
            description:`A 2Smart App permite-lhe ainda uma gestão eficaz de Equipas de Trabalho ou tarefas permitindo a 
            Geolocalização e Geofencing com mapa de rotas e Km's integrado e automatizado.`
          },
        }, 
      },
      integration:{
        title:`Powerful Integration`,
        description1:`O 2Smart HRM integra com todas as Plataformas de Processamento Salarial e Gestão de Recursos Humanos do mercado.`,
        description2:`Seja uma plataforma reconhecida no mercado ou um software desenvolvido à sua medida, o Módulo de Integração com ERP do 2Smart dará a
        resposta necessária para integrar as  suas Assiduidades e automatizar processos de processamento salarial.`,
        softwares:{
          s1:{
            title:"Eticadata",
            label:"software de gestão empresarial",
          },
          s2:{
            title:"Artsoft",
            label:"Software de gestão de contabilidade ",
          },
          s3:{
            title:"Microsoft Dynamics",
            label:"Software de  gestão corporativa ERP",
          },
          s4:{
            title:"Gestware",
            label:"Software de gestão empresarial",
          },
          s5:{
            title:"SAGE",
            label:"Software de gestão de negocios",
          },
          s6:{
            title:"Primavera",
            label:"Software de gerenciamento de projetos corporativos",
          },
          s7:{
            title:"SAP",
            label:"Sistema integrado de gestão empresarial ",
          },
          s8:{
            title:"Meta4",
            label:"Solução de Gestão de Assiduidade",
          },
          s9:{
            title:"PHC",
            label:"Software de gestão empresarial",
          },
          s10:{
            title:"Sendys",
            label:"Software de gestão de negócios & empresas",
          },
          s11:{
            title:"Medidata",
            label:"Sistemas de Informação para Autarquias",
          },
          s12:{
            title:"Alidata",
            label:"Ferramenta essencial para otimizar operações ",
          },
          s13:{
            title:"AIRC",
            label:"Software de gestão",
          },
          s14:{
            title:"F3M",
            label:"Software para mercados verticais",
          },
        }  
      },
      performance:{
        title:`Performance de Excelência`,
        description:`Solução baseada em nuvem que simplifica o gerenciamento de projetos, 
        permitindo que as equipes se concentrem na entrega de resultados de alta qualidade sem as complexidades.`,
        boxes:{
          box1:{
            title:`Truely Cloud!`,
            desc:`Terminais que comunicam com a Cloud sem a necessidade de 
             abertura de portas, redireccionamento de IP's ou domínios dinâmicos!`,
          },
          box2:{
            title:`Empowering Human Resources Management!`,
            desc:`Interface User Friendly, configuração de Horários e Planos de Trabalho simplificado e Inteligente!`,
          },
          box3:{
            title:`Work Smarter!`,
            desc:`Portal do Colaborador integrado, Delegação de Competências associado a um conceito de Workflow inovador!`,
          },
        }
      },
      facts:{
        title1:`Colaboradores Geridos pelo 2Smart HRM `,
        title2:`Empresas trabalham de forma +Inteligente`,
        title3:`Parcerias em 4 Continentes`,
      },
      modules:{
        title:`Transforme a gestão dos seus Recursos Humanos !` 
      },  
      footer:{
        description:`Imaginado com detalhe, Planeado com Rigor, concebido para Simplificar - O 2Smart revolucionou o
        mercado com base na sua abordagem inovadora e   visão out of the box. Gerir Assiduidades é habitualmente  uma Tarefa entediante e onde as plataformas
        existentes são baseadas em processos ultrapassados que não evoluíram com o tempo e não se focam quer nas necessidades do utilizador quer nas necessidades
        dos Gestores de RH.`,
        text0:`We're smarter together`,
        text1:`Venha conhecer o 2Smart HRM!`,
        text2:`Solicitar Demonstração e Proposta`,
        text3:`Sobre a empresa`,
        link1:`Produtos`,
        link2:`Localizações`,
        text4:`Navegação`,
        text5:`Solicitar orçamento`,
        text6:`Contactos`,
        text7:`Casos de sucesso`,
        text8:`Desenvolvido por`,
        text9:`Nossa Política Comercial`,
        text10:`Termos e Condições`,
        text11:` `,
        text12:` `
      }, 
    }, 
    testimonials:{
      title:`Os nossos clientes traduzem o nosso Sucesso!`,
      subtitle:`2Smart HRM - Power to You!`
    }, 
    contacts:{
      title:`Contacte-nos`,
      description1:`2Smart HRM - Power to You!`,
      description2:`O 2smart HRM incorpora o Portal de Colaborador, Chefia e RH e
      dispõe ainda de App's para Android e IOS permitindo a cada  colaborador gerir as suas 
      Férias, Justificar Faltas, Alterações de Turnos, Delegação de Responsabilidades ou
      simplesmente consulta das suas Assiduidades de uma forma cómoda e simplificada.`
    }, 
    requestbudget:{
      title:`Solicitar orçamento`,
      description1:`Solicitar Orçamento ou Demonstração`,
      description2:`Fale Connosco e preencha o formulário abaixo. Um consultor irá entrar em contacto consigo
      para dar a melhor resposta ao seu pedido de proposta, reunião de consultoria,
      demonstração de software ou diagnóstico aprofundado de necessidades.`,
      label0:`Número de colaboradores na empresa`,
      label1:`Nome`,
      label2:`Email`,
      label3:`Empresa`,
      label4:`Telemovel`,
      label5:`Sector`,
      label6:`Mensagem`,
      link_label:`* Concordo com o tratamento dos meus dados de acordo com os`,
      link_text:`Termos e Condições`,
      button:`Enviar`,
      message_alert:`Mensagem enviada com sucesso !`
    },
  }
};












const ENGLISH = {
  translations: {
    navbar: {
      link1:'Home',
      link2:`Success Cases`,
      link3:`Contacts`,
      link4:`Request Quote`,
      lang1:`Portuguese`,
      lang2:`English`,
      lang3:`Spanish`,
    }, 
    home: {
      header: {
        small:`We're Smarter Together!`,
        title1:`2Smart Human Resources Management`,
        title2:`Smart Cloud Attendance Management Platform`,
        description1:`The Cloud Platform with integrated Employee Portal and equipped with AI that helps you manage your HR efficiently.`,
        description2:`* Human resources management without the need to use paper or external software`, 
        button1:`Learn More`,
        button2:`Watch the Video`
      },
      clients_description:`Join the Universe of over 600 clients already using the 2Smart HRM Cloud`,
      features: {
        title:`Unique Features`,
        description: `The 2Smart HRM is an intuitive platform designed to simplify the processes associated with HR management, maintaining full legal compliance.`,
        link_label:`Do you have any questions?`,
        link_text:`Get in touch now`,
        box1: {
          title:"Dashboard",
          description:`Attractive and simplified interface, segmented by access profile and containing the most important metrics, including alerts.`
        },
        box2: {
          title:"Modules",
          description:`The 2Smart HRM has 24 optional Modules that allow HR Managers to take advantage of essential tools for managing their company - Come and get to know them in detail!`
        },
        box3: {
          title:"Planning",
          description:`Whether they are Schedules, complex Shifts, fixed Hours with different staffing objectives or even Exemption from Schedule, the 2Smart HRM provides a solution allowing you to respond to each of your needs... and all in a simplified way. Challenge us, we want to know your needs!`
        },
        box4: {
          title:"Productivity Analysis",
          description:`The 2Smart HRM has adopted a completely different view of everything that exists - come and discover our productivity analysis interface filled with metrics, filters, and alerts that help you focus on what’s important, saving immense time in unnecessary processes.`
        },

        box5: {
          title:"Reports",
          description:`Numerous Reports included in the base version of the 2Smart HRM designed thoughtfully to truly help you analyze what matters and maintain legal compliance. It also enables scheduling for automatic sending of reports to your Managers, HR or Employees on the dates or days of the week you prefer or in specific situations properly pre-configured.`
        },

        box6: {
          title:"Compliance",
          description:`The 2Smart Human Resources Management is in compliance with the Labor Code and the European GDPR regulations. Ensure total compliance in case of inspection and issue the requested Reports in seconds, avoiding unnecessary fines.`
        }, 
        box7: {
          title:"Tools",
          description:`Whether it's Hour Bank, Vacation Management, Absenteeism Classification or Management of daytime or nighttime Overtime, the 2Smart HRM gives you the answer you need in a simple and automated way.`
        }, 
        box8: {
          title:"Onboarding",
          description:`The 2Smart HRM helps you in the Onboarding process - Come and discover the innovative process we implemented and be amazed by the results in your employees' satisfaction!`
        }
      },
      assistent: {
        title:`2Smart - Power to You!`,
        description:`Come and see how the Task and Team Management Module can help you in an innovative way to manage your company, allowing more effective control, simpler delegation of responsibilities, and a series of metrics that help you significantly reduce your costs.`,
        button:`Buy Now`,
        boxes: {
          box1: {
            title:`Attendance Metrics`,
            description:`The 2Smart App allows simple and very intuitive access to your Employee Portal - Justify your Absenteeism, check your attendance metrics or plan your vacation anywhere.`
          },
          box2:{
            title:`Team Management`,
            description:`The 2Smart App also allows you effective management of Work Teams or tasks enabling Geolocation and Geofencing with integrated and automated route mapping and kilometers.`
          },
        }, 
      },
      integration:{
        title:`Powerful Integration`,
        description1:`The 2Smart HRM integrates with all Payroll Processing and Human Resources Management Platforms in the market.`,
        description2:`Whether it's a recognized platform in the market or software developed to your specifications, the 2Smart ERP Integration Module will provide the necessary response to integrate your Attendance and automate payroll processing processes.`,
        softwares:{
          s1:{
            title:"Eticadata",
            label:"business management software",
          },
          s2:{
            title:"Artsoft",
            label:"Accounting management software",
          },
          s3:{
            title:"Microsoft Dynamics",
            label:"corporate ERP management software",
          },
          s4:{
            title:"Gestware",
            label:"business management software",
          },
          s5:{
            title:"SAGE",
            label:"business management software",
          },
          s6:{
            title:"Primavera",
            label:"corporate project management software",
          },
          s7:{
            title:"SAP",
            label:"integrated business management system",
          },
          s8:{
            title:"Meta4",
            label:"Attendance Management Solution",
          },
          s9:{
            title:"PHC",
            label:"business management software",
          },
          s10:{
            title:"Sendys",
            label:"business & company management software",
          },
          s11:{
            title:"Medidata",
            label:"Information Systems for Local Authorities",
          },
          s12:{
            title:"Alidata",
            label:"essential tool to optimize operations",
          },
          s13:{
            title:"AIRC",
            label:"management software",
          },
          s14:{
            title:"F3M",
            label:"software for vertical markets",
          },
        }  
      },
      performance:{
        title:`Excellence Performance`,
        description:`Cloud-based solution that simplifies project management, allowing teams to focus on delivering high-quality results without the complexities.`,
        boxes:{
          box1:{
            title:`Truly Cloud!`,
            desc:`Terminals that communicate with the Cloud without the need for opening ports, redirecting IPs or dynamic domains!`,
          },
          box2:{
            title:`Empowering Human Resources Management!`,
            desc:`User-Friendly Interface, simplified and intelligent scheduling and work plans!`,
          },
          box3:{
            title:`Work Smarter!`,
            desc:`Integrated Employee Portal, Delegation of Responsibilities associated with an innovative Workflow concept!`,
          },
        }
      },
      facts:{
        title1:`Employees Managed by the 2Smart HRM`,
        title2:`Companies work in a more intelligent way`,
        title3:`Partnerships in 4 Continents`,
      },
      modules:{
        title:`Transform the management of your Human Resources!`
      }, 
      footer:{
        description:`Designed with detail, Planned with Rigor, created to Simplify - 2Smart revolutionized the market based on its innovative approach and out-of-the-box vision. Managing Attendance is usually a boring Task where existing platforms are based on outdated processes that have not evolved over time and do not focus either on user needs or on the needs of HR Managers.`,
        text0:`We're smarter together`,
        text1:`Come and discover the 2Smart HRM!`,
        text2:`Request Demonstration and Proposal`,
        text3:`About the company`,
        link1:`Products`,
        link2:`Locations`,
        text4:`Navigation`,
        text5:`Request a quote`,
        text6:`Contacts`,
        text7:`Success cases`,
        text8:`Developed by`,
        text9:`Our Commercial Policy`,
        text10:`Terms and Conditions`,
        text11:` `,
        text12:` `
      },
    },
    testimonials: {
      title: `Our clients reflect our Success!`,
      subtitle: `2Smart HRM - Power to You!`
    },
    contacts:{
      title:`Contact us`,
      description1: `2Smart HRM - Power to You!`,
      description2: `The 2Smart HRM incorporates the Employee, Management, and HR Portal and also provides Apps for Android and iOS allowing each employee to manage their Vacations, Justify Absences, Shift Changes, Delegation of Responsibilities, or simply check their Attendance easily and conveniently.`
    },
    requestbudget: {
      title:`Request a quote`,
      description1: `Request Quote or Demonstration`,
      description2: `Talk to us and fill out the form below. A consultant will contact you to provide the best response to your proposal request, consulting meeting, software demonstration, or in-depth needs diagnosis.`,
      label0:`Number of employees in the company`,
      label1:`Name`,
      label2:`Email`,
      label3:`Company`,
      label4:`Mobile`,
      label5:`Sector`,
      label6:`Message`,
      link_label:`* I agree to the processing of my data in accordance with the`,
      link_text:`Terms and Conditions`,
      button:`Send`,
      message_alert:`Message sent successfully!`
    },
}
}
const SPANISH = {
  translations: {
    navbar: {
      link1: 'Comienzo',
      link2: `Productos`,
      link3: `Servicios`,
      link4: `Contactos`,
      link5: `Tienda`,
      lang1: `Portugués`,
      lang2: `Inglés`,
      lang3: `Español`,
    },
    header: {
      description: `Somos una empresa extremadamente dinámica, formada por profesionales con know-how y motivados por los proyectos en los que nos involucramos. Somos y pretendemos ser cada vez más un referente creciente en las áreas en las que operamos.`,
      button: `Descubra más`
    },
    home: {
      home_text_area1: {
        title: `Tu Aliado en seguridad`,
        text1: `El éxito que nos acompaña desde nuestra fundación en`,
        text2: ` 2006 `,
        text3: `ha sido posible porque contamos con una equipa de profesionales altamente motivados,`,
        text4: ` una planificación estratégica bien definida `,
        text5: ` y socios de excelencia que caminan a nuestro lado, consolidando a Exportech Portugal como una referencia cada vez mayor en el mercado.`,
        text6: ` Marcas de prestígio, equipos confiables, excelente soporte técnico e comercial, flexibilidad de negociación e inmejorable apoyo financiero.  `,
        text7: "#SomosExportech#1"
      },
      circles_text: {
        text1: `Marcas innovadoras`,
        text2: `Flexibilidad y adaptabilidad`,
        text3: `Calidad del equipo`,
      },
      home_text_area2: {
        small: `Apoyamos incondicionalmente a quien nos ayuda a crecer `,
        title: `Un equipo de profesionales listos para ayudar.`,
        text1: `Exportech Portugal dispone de un área técnica con las más recientes tecnologías, que nos
        permiten prestar Soporte Técnico Remoto a nuestros socios/aliados (discutir mejor opción), ayudándolos a solucionar los problemas del día a día.`,
        text2: `Ya sea de manera remota o presencial, asesoría en obra o formación en nuestras oficinas,
         estamos listos para ayudarle a crecer, dotando a su equipo técnico del know-how necesario para ser autónomos con las marcas que distribuimos.`,
        text3: `Podrá solicitar formación personalizada siempre que lo considere necesario en la  `,
        text4: ` Academia Exportech`,
        text5: `permitiendo la programación de la misma de forma autónoma.`,
        text6: `Podrá solicitar formación personalizada siempre que lo considere necesario en la  `,
        text7: `Academia Exportech `,
        text8: `permitiendo la programación de la misma de forma autónoma.`,
      },
      boxes_area: {
        box1: {
          title: `Soporte Técnico`,
          description: `Contactenos y obtenga asistencia técnica remota por parte de nuestros técnicos.`,
        },
        box2: {
          title: `Soporte Presencial`,
          description: `Agende con uno de nuestros técnicos y reciba asistencia en persona.`
        },
        box3: {
          title: `Formulario RMA`,
          description1: `Descargar `,
          description2: "Formulario RMA",
          description3: " , complételo correctamente y envíelo a geral@exportech.com.pt para su rápido procesamiento."
        },
        box4: {
          title: `Contactos`,
        },
      },
      home_text_area3: {
        description: `Les prometemos competencia, competitividad e innovación para dotarles de las herramientas
         necesarias para crecer junto a nosotros. Ofrecemos un acompañamiento comercial y técnico cercano, así como un
          catálogo de soluciones completo y diversificado.`,
        text1: `Desarrolo`,
        text2: `Soluciones integradas de seguridad y Software de Gestión de Asistencia Cloud 2Smart HRM`,
        text3: `Crecimiento`,
        text4: `Hemos crecido continuamente desde 2006 gracias a sólidas asociaciones.`,
        text5: `Marcas`,
        text6: `Representamos a 30 Fabricantes de Prestígio`,
        text7: `Clientes`,
        text8: `Contamos con 1.500 socios-aliados que nos ayudan a crecer cada año.`,
      },
      home_text_area4: {
        small: `Porque a Exportech Portugal`,
        title: `Lo que nos diferencia`,
        description: `Nuestra catalogo de Soluciones de Seguridad e Networking ahora está más 
        completo que nunca. Diversidad, Fiabilidad e Competitividade`,
        text1: `Innovación`,
        text2: ` Línea Estratégica`,
        text3: `Innovamos continuamente en productos y procesos para ser mañana mejores de lo que somos hoy. La innovación forma parte de nuestro ADN.`,
        text4: `Solidez`,
        text5: `Crecimiento Anual`,
        text6: `Hemos crecido de manera sólida desde 2006, reflejando una estrategia exitosa.`,
        text7: `Honestidad`,
        text8: `Postura rigurosa`,
        text9: `Una postura rigurosa de transparencia con nuestros socios, una buena gestión de expectativas, 
        información constante y disponibilidad siempre garantizada.`,
        text10: `Flexibilidad`,
        text11: `Apoyo incondicional`,
        text12: `Flexibilidad en la negociación y en el apoyo financiero. Una estrategia debe ser flexible y adaptable a los 
        constantes cambios del mercado y a cada socio.`,
        text13: `Calidad`,
        text14: `Rigurosa selección de marcas`,
        text15: `Calidad a un precio competitivo. Seleccionamos rigurosamente las marcas y productos que distribuimos, 
        garantizando altos estándares de calidad.`,
        text16: `DESCUBRE MÁS`,
      },
      home_text_area5: {
        small: `Hemos logrado mucho, pero ambicionamos más`,
        title: `Logros a lo largo de los años`,
        description: `17 años de aprendizaje, cientos de proyectos diseñados junto a nuestros socios, 
        momentos de convivencia y amistad, y muchas sonrisas nos han llevado hasta aquí.`,
        text1: `4.4M€`,
        text2: `de Facturación en 2023`,
        text3: `3,000M2`,
        text4: `de Área Logística`,
        text5: `30`,
        text6: `Fabricantes Representados`,
        text7: `17`,
        text8: `años de aprendizaje`,
      },
      home_text_area6: {
        small: `Todos los caminos conducen al éxito.`,
        title: `Conviértase en nuestro socio`,
        description: `Un socio sólido y de confianza, siempre disponible para apoyarlo en todos los aspectos.`,
        description2: `Regístrese en nuestro sitio web y obtenga acceso a un portafolio extremadamente diversificado y competitivo.`,
        text1: `Vamos a crecer juntos ?`,
        button: `Regístrese en el sitio web`
      },
    },
    footer: {
      text1: "Sed Lisboa",
      text2: "Sucursal de Funchal",
      text3: "Almacén logístico",
      text4: "Navegación",
      text5: "Tienda en línea",
      text6: "Cuenta de cliente",
      text7: "Acerca de nosotros",
      text8: "Políticas de privacidad",
      text9: "Libro de quejas",
    },
    about: {
      title_header: `Conozca mas sobre nosotros`,
      about_text_area1: {
        small: `Nosotros somos`,
        title: `Lider en la Distribuicion de Tecnologias de Seguridad y Networking`,
        text1: `Nacimos en 2006 e somos orgullosamente una empresa de Capital 100% Portugues.`,
        text2: `Nuestros pilares fundamentales se basan en la innovación constante de nuestras soluciones, en la honestidad e 
        integridad en las relaciones que establecemos con nuestros socios, en un soporte técnico excepcional, y en el compromiso 
        y la dedicación que asumimos con quienes nos ayudan a crecer.`,
        text3: `El éxito de nuestra estrategia se basa en un equipo de profesionales de excelencia, 
        con pensamiento innovador, ambiciosos y siempre disponibles para nuestros socios, una selección criteriosa de fabricantes 
        y soluciones tecnológicas innovadoras.`,
        text4: `Contamos con un catalogo compuesto por 6.000 artículos con una alta disponibilidad de stock, tres almacenes con 
        una capacidad logística combinada de 3.000 m² y 30 fabricantes representados.`,
        text5: `Estamos llenos de energía que nos permite aspirar a la continuidad de nuestro crecimiento. ¡Estamos listos para más!`,
        text6: `Estamos llenos de energía que nos permite aspirar a la continuidad de nuestro crecimiento.`,
        button: `Ponte en contacto`
      },
      about_text_area2: {
        small: "Nuestro negocio",
        title: `Se destaca del resto`,
        text1: `Nuestra misión`,
        text2: ` Identificar y distribuir soluciones tecnológicas de seguridad electrónica`,
        text3: `Nuestros Valores Fundamentales `,
        text4_1: `Innovación`,
        text4_2: `Integridad`,
        text4_3: `Honestidad`,
        text4_4: `Compromiso`,
        text4_5: `Flexibilidad`,
        text5: `  Nuestra visión  `,
        text6: `Esforzarnos constantemente por mejorar nuestras soluciones. Mañana mejores que hoy.`,
        link: `Ponte en contacto`
      },
      about_text_area3: {
        small: `Diferentes lugares pero con el mismo objetivo`,
        title: `Descubra donde estamos ubicados`,
        link: `Ponte en contacto`,
        text1: `Exportech - Sede  Lisboa`,
        text2: `Exportech  - Filial Funchal`,
        text3: `Exportech - Armazém Logístico`,
      },
      about_text_area4: {
        small: `Company Profile`,
        title: `Somos diferentes en muchos aspectos.`,
        description: `No es posible resumir toda la representación de una empresa
       en simples números y puntos, pero a continuación se presentan nuestras cifras para 2023.`,
        description2: `A su lado, ¡prometemos seguir creciendo!`
      },
      about_text_area5: {
        small: `Equipa Dedicada. `,
        title: `Una Equipa compuesta por profesionales de Excelencia. `,
        description: `Somos una Equipa altamente motivada e con el enfoque en los resultados. #SomosExportech`
      },
    },
    products: {
      title_header: `Productos fascinantes`,
      products_text_area1: {
        title: `Tu Nueva Plataforma de Gestión de Asistencias Cloud`,
        text1: `Imaginado con detalle, planeado con rigor, y diseñado para simplificar - El 2Smart ha
         revolucionado el mercado con su enfoque innovador y visión fuera de la caja. La gestión de 
         asistencias suele ser una tarea tediosa, y las plataformas existentes se basan en procesos 
         obsoletos que no evolucionan ni se centran en las necesidades tanto de los usuarios como de
        los gestores de RH.`,
        text2: `El 2Smart HRM simplifica esta tarea con procesos de configuración completos pero 
        simples y versátiles, y con diseños innovadores que facilitan la percepción y realización de 
        las tareas relacionadas.`,
        text3: `El Poder Está en Ti...`,
        text4: `Simplifique la gestión de recursos humanos con 2Smart HRM`,
        button: `Más información`
      },
      products_text_area2: {
        small: `Líder en Innovación y Competitividad`,
        title: ` Contamos con un catalogo inigualable. Ven a conocer en detalle nuestras soluciones.`,
        text0: `Productos CCTV`,
        text1: `Cables`,
        text2: `Redes`,
        text3: `Fibra óptica`,
        text4: `Sistemas contra incendios`,
        text5: `Videoportero`,
        text6: `Accesorios`,
        text7: `Sistemas de intrusión`,
        text8: `EAS/Antirrobo`,
        text9: `Torniquetes`,
        text10: `Detección de metales`,
        text11: `Detección de gases`,
        text12: `Sistemas de emergencia`,
        text13: `Soluciones de estacionamiento`,
        link: `Visita nuestra tienda online`,
      },
    },
    contacts: {
      title_header: `Contactos`,
      contacts_text_area1: {
        small: `Nuestros contactos`,
        title: `Como entrar en contacto con nuestro equipo de especialistas`,
        description: `Envie un mensaje através del formulario que hemos proporcionado.
        Si su consulta es urgente, utilize los contantos que aparecen a continuación.`,
        text1: `Exportech Portugal - Sede`,
        text2: `Consultas Generales`,
        text3: `Teléfono`,
        text4: `Horario de apertura`,
        text5: `Lunes - Viernes: 9 am a 6 pm (sábado y domingo: cerrado)`,
      },
      contacts_text_area2: {
        title: `Somos un equipo extremadamente motivado y enfocado en resultados. ¿Trabajamos juntos?`,
      },
      contacts_text_area3: {
        small: `lugares diferentes pero con el mismo objetivo`,
        title: `Conozca dónde estamos ubicados`,
        link: `Ver en mapa`
      },
    },

    Terms: {
      block1: {
        title_header: `Política de Privacidad`,
        text1: {
          title: `1. COMPROMISO`,
          p1: `La protección de la privacidad de los usuarios y de sus datos personales constituye un compromiso fundamental de Exportech Portugal.`,
          p2: `Exportech Portugal le recomienda leer esta Política de Privacidad.`,
          p3: `Esta Política de Privacidad fue redactada de manera clara y sencilla, para que usted pueda comprenderla más fácilmente y decidir de manera informada. 
            De forma libre y voluntaria si desea facilitar sus datos personales y explicar qué datos personales Exportech Portugal 
            recopila a través de interacciones con usted y cómo se utilizan esos datos. `,
        },
        text2: {
          title: `2. ÁMBITO DE APLICACIÓN, RESPONSABLE DEL TRATAMIENTO DE DATOS Y RESPONSABLE DE LA PROTECCIÓN DE DATOS`,
          p1: `Esta Política de Privacidad se aplica a los datos personales de nuestros usuarios, clientes, proveedores, candidatos y otras partes interesadas.`,
          p2: `El responsable de recopilar y procesar sus datos personales son las empresas Exportech Portugal que le brindan productos y/o servicios y que, en este ámbito, decide qué datos se recopilan, los medios de procesamiento y los fines para los cuales se recopilan. usado.`,
          p3: `Exportech Portugal ha designado un Delegado de Protección de Datos (DPO), que supervisa el cumplimiento del procesamiento de datos con las normas aplicables,
          es un punto de contacto con el usuario para aclarar cuestiones relativas al tratamiento de sus datos, coopera con las autoridades de control y proporciona información y asesora al responsable o subcontratista sobre sus obligaciones en materia de privacidad y protección de datos`,
        },
        text3: {
          title: `3. DATOS PERSONALES, TITULARES DE LOS DATOS PERSONALES Y CATEGORÍAS DE DATOS PERSONALES`,
          p1: `«Datos Personales», se refiere a información relativa a una persona física identificada o identificable («Titular de los Datos»); Se considera persona física identificable una persona física que puede identificarse, directa o indirectamente, en particular mediante un identificador, como un nombre, un número de identificación, datos de ubicación, identificadores electrónicos o uno o más. 
            elementos específicos de la identidad física, fisiológica, genética, mental, económica, cultural o social de esa persona física;»,`,
          p2: `«Titular de los datos» es la persona física a quien se refieren los datos;`,
          p3: `Esta Política le permite saber cómo Exportech Portugal recopila y utiliza sus Datos personales, cómo puede controlar su uso y describe nuestras prácticas en relación con la información recopilada en todos los sitios web (incluidos, entre otros, aplicaciones de software, redes sociales). y mensajes electrónicos) de Exportech Portugal, así como actividades de marketing y ventas fuera de línea (colectivamente, “Canales de Comunicación”). Los Canales de Comunicación pueden proporcionar enlaces a otras plataformas que no pertenecen a Exportech Portugal. Le recomendamos que consulte las políticas de privacidad de estos sitios web, servicios, redes sociales o aplicaciones antes de proporcionar sus Datos Personales, y no somos responsables del procesamiento de los datos personales que puedan ser proporcionados y/o recopilados en los mismos.`,
          p4: `Confiamos en que nos proporcione información precisa y completa sobre sus datos personales y que los actualice en relación con cualquier cambio en la información proporcionada.`,
        },
        text4: {
          title: `3.1. ¿CUÁNDO Y QUÉ INFORMACIÓN RECOPILAMOS SOBRE USTED? `,
          p1: `Recopilamos tus datos personales, con tu consentimiento, cuando te registras en nuestro sitio web o realizas un pedido de compra de productos o servicios. También recopilamos sus datos personales cuando completa voluntariamente cuestionarios, formularios o utiliza herramientas de chat.`,
          p2: `Le solicitamos que no envíe ni comparta con Exportech Portugal ninguna información personal sensible como origen racial o étnico, opiniones políticas, convicciones religiosas o filosóficas, o afiliación sindical, así como datos genéticos, datos biométricos, relacionados con salud o datos relativos a la vida o la orientación sexual.»`,
          p3: `La información de uso del sitio web se recopila mediante cookies, pero no lo identifican específicamente, y se recopilan o pueden recopilarse los siguientes datos:`,
          p4: `Dirección IP.`,
          p5: `Ubicación geográfica.`,
          p6: `Información sobre el dispositivo que accede al sitio web, es decir, tipo y versión del navegador, sistema operativo, resolución de pantalla, idioma preferido, información contenida en los encabezados HTTP y versión del agente.`,
          p7: `El motor de búsqueda que utilizó para encontrar y acceder al sitio web.`,
          p8: `Fecha y hora de actividad en el sitio web, así como páginas web y contenidos visitados y en los que se hizo clic.`,
          p9: `Es importante resaltar que esta información de uso no lo identifica deliberadamente. y si es excepcional (la identificación será inmediatamente anónima.`,
          p10: `Al facilitar información estás dando tu consentimiento informado e inequívoco que consientes el tratamiento de los datos que te conciernen de forma automatizada. El mencionado consentimiento previo y expreso del interesado se prestará por escrito, de forma oral o mediante validación. de una opción.`,
          p11: `Algunos de los datos solicitados son obligatorios y están debidamente marcados, y la falta o inexactitud de las respuestas dadas puede comprometer el servicio prestado.`,

        },
        text5: {
          title: `3.2. ¿CÓMO UTILIZAMOS LA INFORMACIÓN SOBRE USTED?`,
          p1: `Los datos recopilados se utilizan para procesar su pedido, administrar su cuenta y, si está de acuerdo, enviarle información sobre otros productos y servicios por correo electrónico.`,
          p2: `Utilizamos la información recopilada del sitio web para personalizar sus próximas visitas a nuestro sitio web.`,
          p3: `Si está de acuerdo, enviaremos su información personal a las distintas empresas de Exportech Portugal para que puedan ofrecerle sus productos y servicios. `,
          p4: `Exportech Portugal no comparte su información para campañas de marketing con empresas ajenas al grupo ni transfiere sus Datos Personales a un tercer país.`,
          p5: `Aunque la información proporcionada es generalmente tratada como privada en Exportech Portugal, los datos recopilados serán utilizados de acuerdo con la normativa de protección de datos personales, durante todo el proceso. 
          de tratamiento; Sin embargo, puede ser necesario transferir datos personales de forma privada a otros organismos, en particular el poder judicial, y/o utilizar información de agencias de crédito/compañías de seguros o similares.`,
          p6: `Tiene derecho a presentar una queja ante una Autoridad de Control Nacional y/o de la Unión Europea en relación con cualquier violación de sus Datos Personales. 
          Exportech Portugal prestará su colaboración a la Autoridad de Control facilitándole toda la información que esta le solicite, en el ejercicio de sus competencias. `,
          p7: `Exportech Portugal sólo acepta registros de usuarios que tengan al menos 16 años. Los Canales de Comunicación de Exportech Portugal no están dirigidos
               a usuarios menores de edad. Si tiene conocimiento de algún registro realizado por un menor de edad, le agradeceríamos que nos avisara para que podamos eliminar el registro de inmediato. `,
          p8: `Los destinatarios o categorías de destinatarios de sus Datos Personales, dependiendo de la herramienta de contacto que utilice, son los departamentos involucrados en la relación comercial, 
              los departamentos de Marketing, el Departamento de Personas y otros departamentos de las diferentes empresas de Exportech Portugal.`,
          p9: `Cada vez que transfiere o descarga un paquete de software en cualquier sitio web de cualquier empresa Exportech Portugal, puede estar en contacto con los objetivos
              supervisar la experiencia de uso y presentación del software en cuestión y/u otros productos similares.»`,
          p10: `Decisiones automatizadas: `,
          p11: `La información recopilada en los sitios web de Exportech Portugal no implica la toma de decisiones automatizada, incluida la definición de perfiles.  `,
          p12: `Sus datos pueden circular en una red abierta y correr el riesgo de ser vistos y utilizados por terceros no autorizados.`,
          p13: `En los sitios web de Exportech Portugal no se realizan comparaciones, interconexiones o cualquier otra forma de interrelacionar la información registrada. `,
        },
        text6: {
          title: `3.3. COMERCIALIZACIÓN`,
          p1: `Nos gustaría poder enviarle información sobre los productos y servicios de las empresas de Exportech Portugal que puedan ser o puedan ser de su interés. si sucediera 
          Con tu consentimiento previamente siempre podrás optar por darte de baja. Tienes derecho en cualquier momento a no 
          permitirle ser contactado para campañas de marketing. `,
        },
        text7: {
          title: `3.4. CONDICIONES GENERALES DE SUMINISTRO`,
          p1: `Las condiciones generales de suministro de Exportech Portugal están disponibles en los sitios web de Exportech Portugal.`,
        },
        text8: {
          title: `3.5. TRANSMISIÓN DE DATOS PERSONALES`,
          p1: `Sus datos podrán ser transmitidos a subcontratistas para que puedan procesarlos en nombre y por cuenta de las empresas de Exportech Portugal.`,
          p2: `Las entidades subcontratadas, así como las entidades que prestan o otorgan licencias de servicios a las empresas de Exportech Portugal, pueden tener acceso a los datos recopilados y registrados por las empresas de Exportech Portugal, cuando y en la medida necesaria 
               para ofrecer al Cliente productos o servicios vendidos por las empresas de Exportech Portugal, o para cumplir con las obligaciones contractuales establecidas entre las empresas de Exportech Portugal y el Cliente.`,
        },
        text9: {
          title: `3.6. RECLUTAMIENTO Y SELECCIÓN`,
          p1: `Al presentar una solicitud espontánea o una solicitud de oportunidad, usted autoriza que sus datos personales recopilados sean procesados ​​y almacenados electrónicamente por Exportech Portugal con el 
              con el fin de ser contactados para procesos abiertos de reclutamiento y selección, o que puedan surgir en el futuro, para empresas de Exportech Portugal.`,
          p2: `Sus datos personales se conservan durante 2 años, después de los cuales se eliminan. Deberá presentar una nueva solicitud después de este período si desea seguir apareciendo en la base de datos de Exportech Portugal.`,
          p3: `Si se integra en un proceso de reclutamiento y selección, este proceso se mantendrá durante 5 años.`,
          p4: `Los destinatarios o categorías de destinatarios de sus datos personales son el Departamento de Recursos Humanos, la Administración, y otros departamentos o funciones relevantes para el análisis de la solicitud.`,
          p5: `Sus datos personales también podrán ser compartidos con otras entidades cuando así lo requiera la ley o para responder a un proceso legal, y también en situaciones que involucren la protección de clientes, la protección de vidas, la seguridad de los servicios, la protección de los derechos de propiedad. o empresas Exportech Portugal.`,
          p6: `Los procesos de contratación no implican la toma de decisiones automatizada, incluida la elaboración de perfiles.`,
          p7: `No se realizan comparaciones, interconexiones o cualquier otra forma de interrelacionar la información registrada.`,
          p8: `Se requiere consentimiento para el procesamiento posterior de datos personales para un fin distinto de aquel para el cual se recopilaron los datos`,
          p9: `Exportech Portugal sólo acepta solicitudes de personas que tengan al menos 16 años, que hayan completado la educación obligatoria y que tengan las capacidades físicas y mentales adecuadas para el trabajo.`
        },
        text10: {
          title: `3.7. ÉTICA`,
          p1: `El código de ética de Exportech Portugal establece los valores y principios éticos de las actividades de Exportech Portugal y
               cómo deberían reflejarse en nuestras relaciones con las diferentes partes interesadas.`,
          p2: `Para denunciar malas prácticas éticas, deberá consentir el tratamiento de sus datos personales, con esta finalidad y como forma de salvaguarda 
              riesgos de denuncias difamatorias y discriminación. La falta de consentimiento determina la no tramitación de la comunicación y su supresión de los datos contenidos en la misma.`,

          p3: `Sus datos personales serán eliminados inmediatamente si resultan inexactos o inútiles; serán almacenados durante 6 meses después del final de las investigaciones si no existe un proceso disciplinario o judicial y hasta el final de las 
              proceso si existe procedimiento disciplinario o judicial. En este caso, sus datos personales serán almacenados en un sistema de información de acceso restringido por un período que no excederá el proceso disciplinario o judicial.`,
          p4: `Sus datos personales también podrán ser compartidos con otras entidades cuando así lo exija la ley o para responder a procesos legales, y también en situaciones que impliquen la protección de clientes, protección de vidas, seguridad de servicios, protección de derechos de propiedad o empresas de Exportech Portugal .`,
          p5: `Los procesos de contratación no implican la toma de decisiones automatizada, incluida la elaboración de perfiles. No se realizan comparaciones, interconexiones ni cualquier otra forma de interrelación de la información registrada.»`
        },
        text11: {
          title: `4. PLAZOS Y BASE PARA EL TRATAMIENTO Y ALMACENAMIENTO DE DATOS PERSONALES`,
          p1: `Sin perjuicio de disposiciones legales o reglamentarias en contrario, los Datos Personales serán almacenados/procesados ​​únicamente por el
               plazo mínimo necesario para los fines que motivaron su recogida o posterior tratamiento.».`,
          p2: `El procesamiento posterior de Datos Personales para una finalidad distinta a aquella para la cual fueron recopilados requiere el consentimiento, antes de dicho procesamiento las empresas del
               Exportech Portugal, solicitará el consentimiento respectivo y proporcionará al Cliente información relativa a este fin y cualquier otra información pertinente.`,
          p3: `Exportech Portugal conservará sus datos personales mientras mantenga la relación contractual establecida con el titular de los datos personales.`,
          p4: `Hay casos en los que la ley exige el tratamiento y conservación de los datos durante un período mínimo de tiempo.`,

          p5: `En ausencia de una obligación legal específica, los datos serán procesados/conservados únicamente durante el período de tiempo necesario para cumplir con los fines que motivaron su recolección y conservación o, 
          según sea aplicable, hasta que ejerza su derecho de oposición, derecho al olvido o retirar el consentimiento y siempre de conformidad con la ley, las directrices y decisiones de las autoridades de control.`,
          p6: `El Cliente tiene garantizado, en términos legales, el derecho a retirar su consentimiento en cualquier momento, sin comprometer la licitud del procesamiento realizado sobre la base del consentimiento previamente otorgado y el derecho a solicitar a las empresas Exportech Portugal el acceso a los Datos Personales que le conciernen. usted, así como su rectificación 
          o su supresión, y la limitación del tratamiento respecto del Cliente, o el derecho de oposición al tratamiento, así como el derecho a la portabilidad de los datos mediante comunicación escrita dirigida a Exportech Portugal.`,
          p7: `Los Datos Personales necesarios para la ejecución del contrato del que el Cliente es parte, para el cumplimiento de las obligaciones legales a las que está sujeto el Responsable del Tratamiento y aquellos necesarios para los fines de los intereses legítimos perseguidos por las empresas de Exportech Portugal no son están incluidos en el punto anterior.`,
          p8: `Después de transcurrido el período de conservación/procesamiento, Exportech Portugal eliminará o, si es de interés de las empresas de Exportech Portugal, anonimizará (de tal manera que el Cliente no sea o ya no pueda ser identificado) los datos siempre que no deben conservarse para un fin distinto que pueda subsistir.`,
        },
        text12: {
          title: `4.1. BASE PARA EL TRATAMIENTO DE DATOS PERSONALES`,
          p1: `Consentimiento: cuando se cuenta con el consentimiento previo y expreso del interesado –por escrito, oralmente o validando una opción- y si este consentimiento es libre, informado, específico e inequívoco.`,
          p2: `– caso especial de menores:`,
          p3: `En el caso de tratamientos de datos personales de menores de edad, que puedan estar sujetos a consentimiento previo, éste deberá ser proporcionado por los titulares de la responsabilidad parental.`,
          p4: `Ejecución del contrato: cuando el tratamiento de datos personales sea necesario para la celebración, ejecución y gestión del contrato celebrado con cualquiera de las empresas de Exportech Portugal.`,
          p6: `Cumplimiento de obligación legal: cuando el tratamiento de datos personales es necesario para cumplir con una obligación legal a la que está sujeto Exportech Portugal.`,
          p7: `Interés legítimo: cuando el tratamiento de datos personales corresponde a un interés legítimo de Exportech Portugal o de terceros.`,
        },
        text13: {
          title: `5. GALLETAS`,
          p1: `Exportech Portugal utiliza cookies en todos nuestros sitios web para mejorar el rendimiento y su experiencia de usuario.`,
          p2: `El uso de cookies por parte de Exportech Portugal tiene como objetivo analizar el uso del sitio web y permitirle tener una experiencia de navegación sin problemas; En ningún momento recogemos Datos Personales a través de cookies.`,
          p3: `No se utilizarán cookies para ninguna finalidad distinta a las mencionadas en el punto anterior.`,
          p4: `Las cookies son pequeños archivos de texto que un sitio web, cuando es visitado por el usuario, coloca en su computadora o dispositivo móvil a través del navegador de Internet (navegador). Esta información se utiliza para registrar el número de visitas realizadas y recopilar información estadística sobre la actividad del sitio web.`,
          p5: `Utilizamos el término cookies en esta Política para referirnos a todos los archivos que recopilan información de esta manera.`,
          p6: `Tipos de Cookies según la entidad que las gestiona:`,

          p7: `Cookies propias: una cookie gestionada por este dominio/sitio web.`,
          p8: `Cookies de terceros: una cookie administrada por otro dominio/sitio web.`,
          p9: `Cookies persistentes: la información de las cookies se almacena permanentemente en su dispositivo.`,
          p10: `Cookies de sesión: la información de las cookies no se almacena permanentemente en su dispositivo.`,
          p11: `Exportech Portugal utiliza cookies de sesión (“cookies de sesión”) y cookies de origen (“cookies de origen”) así como cookies persistentes (“cookies persistentes”) y cookies de terceros (“cookies de terceros” ).`,
          p12: `Tipos de cookies según la finalidad de uso:`,

          p13: `Cookies técnicas: son aquellas que permiten navegar a través de un dominio/sitio web, utilizando diferentes opciones o servicios como, por ejemplo, identificar la sesión, acceder a áreas de acceso restringido y
          realizar el proceso de una orden de compra.`,
          p14: `Cookies de personalización: son aquellas que permiten el acceso al dominio/sitio web con algunas características predefinidas en función de un conjunto de decisiones tomadas, como por ejemplo el idioma utilizado.`,
          p15: `Cookies de análisis: son aquellas que permiten el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. 
          La información recogida a través de dichas cookies se utiliza, por ejemplo, para medir la actividad del sitio web y crear perfiles de navegación.`,
          p16: `Cookies publicitarias: son aquellas que permiten la gestión más eficaz de los espacios publicitarios en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.`,
          p17: `Cookies de publicidad comportamental: son aquellas que permiten la gestión de la forma más eficaz posible los espacios publicitarios. 
          Estas cookies almacenan información sobre el comportamiento del usuario, obtenida a través de la observación continuada de sus hábitos de navegación, permitiéndonos desarrollar un perfil específico para mostrar publicidad en función del mismo.`,
        },
      },
      block2: {
        title_header: `Términos y condiciones`,
        text1: {
          title: `1. ÁMBITO DE APLICACIÓN`,
          p1: `1.1. Las presentes Condiciones Generales establecen las condiciones aplicables a las ventas realizadas por Exportech, en adelante la Compañía, y el Cliente, en adelante el Cliente, y regulan los derechos y obligaciones de las partes, prevaleciendo sobre cualesquiera disposiciones legales no imperativas. `,
          p2: `1.2. Cualquier excepción o modificación a estas Condiciones Generales toma la forma de Condiciones Particulares y sólo será válida si está formulada por escrito, aceptada y firmada por los representantes legales de las partes, prevaleciendo estas últimas sobre las primeras.`,
          p3: `1.3. Serán nulas de pleno derecho cuantas condiciones o especificaciones que el Cliente pueda insertar en documentación de cualquier naturaleza que sean contradictorias con lo dispuesto en las Condiciones Generales o Particulares. `,
        },
        text2: {
          title: `2. SELECCIÓN DE PRODUCTO`,
          p1: `2.1. El Cliente es el único responsable de la selección del producto objeto de compra y venta, así como del uso o función al que está destinado. Por lo tanto (y de acuerdo con lo estipulado en los catálogos, tablas de precios y/o información general del producto de la Empresa), la Empresa no se responsabiliza ni garantiza que el producto sea apto para las aplicaciones técnicas previstas por el Cliente. , ni para la consecución, total o parcial, de los objetivos perseguidos por él al realizar su compra. Las imágenes contenidas en este catálogo son meramente ilustrativas y pueden diferir del aspecto original del
          equipamiento, por lo tanto, colores, formas y acabados están sujetos a posibles cambios, sin previo aviso.`,
        },
        text3: {
          title: `3. PEDIDOS`,
          p1: `3.1. En la fecha de pedido de los bienes y/o servicios que el Cliente pretende comprar, el Cliente está obligado a realizar la solicitud por escrito utilizando el formulario de la Compañía apropiado para ese fin, o por correo electrónico a comecial@store.exportech.com. .`,
          p2: `3.2. El plazo de entrega de la mercancía encargada, indicado por la Empresa, es meramente indicativo y en ningún caso vinculante, por lo que la Empresa no asume ninguna responsabilidad por la entrega de la mercancía fuera del plazo inicialmente indicado.`,
        },
        text4: {
          title: `4. INSPECCIÓN DE MERCANCÍAS`,
          p1: `4.1. Todos los Bienes se venden con la calidad que poseen y en las condiciones en que se encuentran en la fecha de venta. Los Clientes podrán inspeccionar los bienes objeto de venta, previo acuerdo de la Empresa, considerando que el Cliente ha examinado los bienes objeto de venta o renunciado a este derecho desde el momento en que se presenta a la Empresa la respectiva propuesta de compra. `,
        },
        text5: {
          title: `5. CANTIDADES`,
          p1: `5.1 Cualquier diferencia en las cantidades en las ventas de bienes realizadas por unidad debe ser comunicada inmediatamente a la Compañía por el Cliente, en el momento de la entrega o recogida de los bienes. En cualquier caso, la Empresa se reserva el derecho de inspeccionar dichos bienes antes de aceptar la reclamación realizada por el Cliente. `,
        },
        text6: {
          title: `6. CONDICIONES DE PAGO`,
          p1: `6.1. Salvo pacto en contrario por escrito, y sin perjuicio de lo dispuesto en la cláusula 3a, el precio de los bienes adquiridos por el Cliente será pagado a la Empresa en efectivo, previa emisión de la respectiva factura o documento equivalente. La falta de pago da lugar a la resolución inmediata del contrato y al incumplimiento del Cliente. `,
          p2: `6.2. La Compañía puede cambiar el precio de los bienes sin previo aviso. Sin embargo, siempre se le pagará el valor de la mercancía en la fecha de su pedido o, si la venta no fue precedida por un pedido, en la fecha de su pago.`,
          p3: `6.3. Los precios establecidos por la Sociedad están sujetos al IVA calculado al tipo legal vigente en la fecha de emisión de la factura de pago de los bienes adquiridos por el Cliente.`,
          p4: `6.4. Los bienes, incluso si están en posesión del Cliente, continúan siendo propiedad de la Compañía hasta que se cumplan plenamente todas las obligaciones del Cliente y la respectiva liquidación de los bienes en su totalidad.`,
          p5: `6.5.Todas las ventas no liquidadas en la fecha de vencimiento están sujetas a intereses de demora al tipo de interés medio aplicado por los bancos portugueses a los descubiertos en cuentas corrientes calculado en la fecha de vencimiento.`,
        },
        text7: {
          title: `7. SUMINISTRO, ENTREGA Y RECOGIDA DE MERCANCÍAS`,
          p1: `7.1. Los bienes se considerarán puestos a disposición del Cliente en el lugar definido por la Compañía, salvo acuerdo en contrario por escrito.`,
          p2: `7.2. Si la mercancía se entrega en un lugar definido por el Cliente, los gastos de envío correrán a cargo del Cliente y el riesgo correrá a cargo del Cliente.`,
          p3: `7.3. El Cliente deberá recoger los bienes adquiridos dentro de los 3 (tres) días hábiles posteriores al pago.`,
          p4: `7.4. Si el Cliente no realiza ningún pago a su vencimiento o no cumple oportunamente otras obligaciones que se le asignan, la Compañía podrá suspender el cumplimiento de sus funciones, en particular, limitando o cesando el soporte técnico.`,
        },
        text8: {
          title: `8. SOPORTE TÉCNICO`,
          p1: `8.1. El Cliente podrá en cualquier momento solicitar servicios al Departamento Técnico de la Empresa, los cuales serán prestados y cobrados en las condiciones y términos que defina la Empresa. `,
          p2: `8.2. Cualquier coste inherente al Servicio de Soporte Técnico será previamente comunicado al Cliente y aceptado por el Cliente por escrito.`,
        },
        text9: {
          title: `9. QUEJAS`,
          p1: `9.1. El plazo de reclamación es de 8 (ocho) días y siempre deberá ser justificado por el Cliente y acompañado de los bienes que motivaron el reclamo en las mismas condiciones en que fueron vendidos. `,
          p2: `9.2. El cliente deberá solicitar a la Empresa que le asigne un número de reparación/devolución (RMA) que identificará el producto, y sin el cual no será posible su análisis por parte del Departamento Técnico de la Empresa.`,
          p3: `9.3. Sólo se aceptarán reclamaciones previa opinión favorable del Departamento Técnico de la Empresa, el cual determinará si el equipo en cuestión deberá ser reparado o reemplazado, según lo decida la Empresa.`,
          p4: `9.4. El dictamen del Departamento Técnico se realizará en un plazo máximo de 15 (quince) días hábiles, luego de la recepción del material en la sede de la Empresa.`,
          p5: `9.5. Sólo se aceptarán cambios o devoluciones si se demuestra que el equipo está defectuoso.`,
          p6: `9.6. Todos los equipos enviados para reparación o cotizaciones de reparación solo se aceptarán cuando vayan acompañados de un formulario RMA debidamente completado (disponible en el sitio web de la Compañía).`,
        },
        text10: {
          title: `10. REPARACIONES`,
          p1: `10.1. A petición y requerimiento del Cliente, y previo contacto con el Departamento Técnico de la Compañía, los equipos adquiridos y pagados íntegramente por el Cliente podrán ser reparados en la sede de la Compañía.`,
          p2: `10.2. El Cliente deberá solicitar a la Empresa que le asigne un número de reparación/devolución (RMA) que identificará el producto, y sin el cual no será posible su análisis por parte del Departamento Técnico de la Empresa.  `,
          p3: `10.3. El equipo:`,
          p4: `10.3.1. Tras un análisis exhaustivo, no se detectó ninguna anomalía.`,
          p5: `10.3.2. No hay descripción detallada del fallo.`,
          p6: `10.3.3. En los casos en que no se aceptó el presupuesto de reparación propuesto, estarán sujetos al pago de una tarifa de análisis del equipo correspondiente al 15% del valor nuevo del mismo equipo. `,
          p7: `10.3.4. La Compañía excluye toda responsabilidad derivada del transporte del equipo a reparar.`,
        },
        text11: {
          title: `11. ELEMENTOS DE FUERZA MAYOR RELACIONADOS CON LA ENTREGA`,
          p1: `11.1. Después de la celebración del Contrato, la Compañía no será responsable de ningún retraso en la puesta a disposición del Cliente de los bienes objeto del Contrato, si dicho retraso se debe a fuerza mayor, caso fortuito o, en general, a situaciones que no pudieron razonablemente previsible o evitable.»`,
        },
        text12: {
          title: `12. LÍMITES DE RESPONSABILIDAD DE LA EMPRESA`,
          p1: `12.1. En el caso de que, por cualquier motivo, exista retraso o falta en la entrega de los bienes, defecto de cantidad, género o cualquier inexactitud en la descripción de los bienes objeto 
          del Contrato, incluso si se debe a culpa o negligencia de la Compañía, la responsabilidad de esta última no cubrirá, en ningún caso, los daños indirectos sufridos por el Cliente, tales como la pérdida de una operación de reventa, negocio u otros beneficios similares o lucro cesante.`,
        },
        text13: {
          title: `13. RESOLUCIÓN`,
          p1: `13.1. La Compañía tiene derecho a rescindir, total o parcialmente, el Contrato con efecto inmediato en los siguientes casos: `,
          p2: `13.1.1. Incumplimiento de las Condiciones Generales y/o Particulares;`,
          p3: `13.1.2. La sujeción a cualquier proceso de insolvencia, liquidación judicial o extrajudicial del Cliente, 
          la reducción de las garantías prestadas o, en general, cualquier modificación de su estructura jurídica que afecte a su solvencia;`,
          p4: `13.1.3. Terminación, por cualquier motivo, aunque sólo sea el cese de facto, de la actividad del Cliente;`,
          p5: `13.1.4. Concurrencia de cualesquiera otras causas de resolución previstas en el Contrato o en la Ley.`,
        },
        text14: {
          title: `14. REDUCCIÓN DEL CONTRATO`,
          p1: `14.1. La nulidad, total o parcial, de cualquier disposición del Contrato, no afectará la validez de las restantes disposiciones, salvo que el interesado demuestre que la finalidad perseguida por la Empresa y el Cliente nos permite suponer que no habrían celebrado el contrato. transacción sin la parte inválida.`,
        },
        text15: {
          title: `15. CLÁUSULA PENAL`,
          p1: `En caso de retraso en el pago de una factura y su continuación después de que la Compañía haya solicitado 
          al Cliente el pago, el Cliente deberá pagar a la Compañía, además del monto correspondiente a la deuda principal y 
          los intereses respectivos, una cantidad correspondiente al 15% del importe adeudado, en concepto de cláusula penal.`
        },
        text16: {
          title: `16. JURISDICCIÓN COMPETENTE`,
          p1: `Para resolver cualquier cuestión que surja de este contrato, las partes acuerdan acudir al Tribunal de Distrito de Lisboa.`,
        },
      },
      block3: {
        title_header: `Política de cambios y devoluciones`,
        subtitle: "CONDICIONES DE ACEPTACIÓN",
        text1: {
          numb: "1.",
          txt: `Las RMA siempre deben ir acompañadas de este formulario;`
        },
        text2: {
          numb: "2.",
          txt: `Todos los Accesorios que acompañan al RMA deben estar indicados expresamente, de lo contrario no se aceptarán solicitudes de accesorios no indicados posteriormente. Si se indican accesorios que no están incluidos con el equipo, se devolverá el RMA y los costos de transporte correrán a cargo del Cliente;`
        },
        text3: {
          numb: "3.",
          txt: `Los RMA que no revelen ningún daño se enviarán con los costos de transporte a cargo del Cliente y se cobrará una tarifa de 15,00 € por la Asistencia brindada;`
        },
        text4: {
          numb: "4.",
          txt: `Cuando en el Período de Garantía, el Cliente corre con los costos de transporte a Exportech y Exportech corre con los costos de envío al cliente;`
        },
        text5: {
          numb: "5.",
          txt: `Las solicitudes de crédito deben realizarse en un plazo máximo de 14 Días Hábiles después de la emisión de la Factura, y a partir de esa fecha están sujetas a Evaluación y Aceptación por parte de Exportech Portugal;`
        },
        text6: {
          numb: "6.",
          txt: `Sólo se aceptarán para Crédito Productos cuya verificación por parte de nuestros Servicios Técnicos verifique que los 4 Supuestos anteriores estén validados (Operación Normal | Paquete Completo | Caja en Buen Estado | Equipo Vendible);`
        },
        text7: {
          numb: "7.",
          txt: `El equipo que no sea recogido o cuyo Pago de Reparación no sea pagado dentro de los 60 Días revertirá a Exportech Portugal;`
        },
        text8: {
          numb: "8.",
          txt: `Todo equipo fuera del periodo de garantía está sujeto a un cargo de 15,00 € por el análisis respectivo. Este valor se diluirá en el costo de la Reparación si se acepta el Presupuesto;`
        },
        text9: {
          numb: "9.",
          txt: `Todas las reparaciones se benefician de una garantía de 3 meses;`
        },
        text10: {
          numb: "10.",
          txt: `Todos los equipos para notas de crédito deben mencionar la contraseña del equipo en el campo de
           arriba. Si olvida o pierde su(s) contraseña(s), Exportech le cobrará 25€+IVA por el servicio único.`
        },
        text11: {
          numb: "11.",
          txt: `Emisión de Notas de Crédito al momento de devolución del equipo por parte de los clientes: Hasta 15 días* – Sin devaluación 16 – 31 días* – 15% devaluación 32 – 60 días* – 40% devaluación *(suponiendo que el equipo sea perfectamente vendible, embalaje original, libre de riesgos y/o uso, accesorios, etc.); Después de 61 días – Exportech no acepta devoluciones.`
        },
        text12: {
          numb: "12.",
          text: `12. Productos de Proyecto o Pedido destinado a un proyecto (Productos sin Stock) – Exportech no acepta devoluciones;`
        },
      },
    },

    about_locations: {
      l1: `Sede de Exportech Lisboa`,
      l2: `Sucursal Exportech Funchal`,
      l3: `Almacén Logístico`,
    },

  }
}

export default {
  'PT': PORTUGUESE,
  'EN': ENGLISH,
  'ES': SPANISH,
};
