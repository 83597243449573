import React from 'react'
import { GrCloudDownload } from "react-icons/gr";
import { PiUsersThree } from "react-icons/pi";
import { PiBuildings } from "react-icons/pi";
import Translator from './I18n/Translator';
import { BsGlobeEuropeAfrica } from "react-icons/bs";


function FactsSection() {
  return (
    <section className='facts-section bg-section'>
         <div className="wrapper">
             <div className="content">
                  <article>
                    <div className="icon bg-main text-white"><PiUsersThree /></div>              
                     <div className="block-info">
                        <h4 className='title-3'>+27.000 </h4>    
                        <p className="description-3"><Translator path="home.facts.title1" /> </p> 
                    </div>
                  </article>
                  <article>
                    <div className="icon bg-main text-white"><PiBuildings/></div>              
                     <div className="block-info">
                        <h4 className='title-3'>+600</h4>    
                        <p className="description-3"><Translator path="home.facts.title2" /> </p> 
                    </div>
                  </article>
                  <article>
                    <div className="icon bg-main text-white"><BsGlobeEuropeAfrica/></div>              
                     <div className="block-info">
                        <h4 className='title-3'>+100</h4>    
                        <p className="description-3"><Translator path="home.facts.title3" /> </p> 
                    </div>
                  </article>
             </div>
             <div className="bar"></div>
         </div>
    </section>
  )
}

export default FactsSection
