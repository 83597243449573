import React, { useEffect, useState } from 'react'
import { AiFillThunderbolt } from "react-icons/ai";
import { FaCirclePlay } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import ImageLoading from './ImageLoading';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import CustomersSection from './CustomersSection';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Translator from './I18n/Translator';


function Header() {
  const [Position, setPosition] = useState(false);   
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false); 
  const handleShow = () => setShow(true);

  function MoveFrame(){
    window.addEventListener("scroll", (e) => {
      let height = document.body.offsetHeight > 4000 ? (document.body.offsetHeight - 1050) : document.body.offsetHeight;
      let scroll = window.scrollY;
      const val = Math.floor(((scroll + 200) * 100) / height); 
      setPosition(Math.floor(window.scrollY) > 400 ? true : false);
    }); 
  }
 

  useEffect(() => {
    MoveFrame(); 
  }, []);


  const Images = [
    {
      id: 1,
      source: require("../Assets/Images/mocks/1.png")
    }, 
  ];
 
  var settings = {
    dots: false,
    infinite: true,
    fade: true, 
    slidesToShow: 1,
    slidesToScroll: 1, 
    autoplay: true,
    speed:5000,
    autoplaySpeed:5000,
    cssEase: "linear"
  };
  

  return (
    <div className='header-main'>
      <Modal show={show} fullscreen size='xl' onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> <h5 className='text-white'>2Smart Apresentação</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="video-presentation">
            <iframe src="https://www.youtube.com/embed/KDwhpMY-WJ0?si=dG_WhAl4loVDdKmM" title="YouTube video player"
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </Modal.Body>
      </Modal>
      <section className='bg-black  header'>
        <div className="wrapper">
          <div className="content h1"> 
            <div className="box-info"><AiFillThunderbolt /> <span><Translator path="home.header.small" /> </span></div>
            <h1 className="title-1"><Translator path="home.header.title1" /></h1> 
            <p className='description-0'><Translator path="home.header.description1" />  </p>
            <div className="buttons">
              <Link to="/request_budget"><button className="btn text-white bg-main"><Translator path="home.header.button1" /></button></Link>
              <button onClick={handleShow} className="btn bg-white"><FaCirclePlay /><Translator path="home.header.button2" /></button> 
              <div className="ani-el an-1">
                <div className="block-ln  b1">
                  <div className="ln ln-1"> <div className="dot"></div></div>
                  <div className="ln ln-2"> <div className="dot"></div></div>
                  <div className="ln ln-3"> <div className="dot"></div></div>
                  <div className="ln ln-4"> <div className="dot"></div></div>
                </div>
                <div className="block-ln b2">
                  <div className="ln ln-1"> <div className="dot"></div></div>
                  <div className="ln ln-2"> <div className="dot"></div></div>
                  <div className="ln ln-3"> <div className="dot"></div></div>
                  <div className="ln ln-4"> <div className="dot"></div></div>
                </div>
              </div> 
              <div className="ani-el an-2">
                <div className="block-ln b2">
                  <div className="ln ln-1"> <div className="dot"></div></div>
                  <div className="ln ln-2"> <div className="dot"></div></div>
                  <div className="ln ln-3"> <div className="dot"></div></div>
                  <div className="ln ln-4"> <div className="dot"></div></div>
                </div>
                <div className="block-ln  b1">
                  <div className="ln ln-1"> <div className="dot"></div></div>
                  <div className="ln ln-2"> <div className="dot"></div></div>
                  <div className="ln ln-3"> <div className="dot"></div></div>
                  <div className="ln ln-4"> <div className="dot"></div></div>
                </div>
              </div>
            </div>  
          </div>
        </div>
        <div className="wrapper">
          <div className="content">
            <div className="content-data">
              <div className={Position ? "framer-app nm" : "framer-app"}>
                <div className="frame-header">
                  <div className="dot d1"></div>
                  <div className="dot d2"></div>
                  <div className="dot d3"></div>
                </div>
                <div className="cover">
                  <div className="cover-image"> 
                      <div className='item-box' >
                        <ImageLoading height={540} source={window.innerWidth <= 800 ?
                          require("../Assets/Images/frame1.jpg") :
                          require("../Assets/Images/mocks/1.png")} alt="2smart" />
                      </div> 
                  </div>
                </div>
              </div> 
              <CustomersSection /> 
            </div>
          </div>
        </div> 
      </section> 
      <div className="height-box bg-dark" id='bx' style={{ width:0}}></div>
    </div>
  ) 
}

export default Header


