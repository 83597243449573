import React, { useEffect, useState } from 'react'
import { FaChevronUp } from 'react-icons/fa';

function ScrollToTop() {
    const [ShowScrollItem, setShowScrollItem] = useState(false)
    const [DH, setDH] = useState("")
  
  
    function MoveFrame() { 
        window.addEventListener("scroll",  (e)=> {
          let height = document.body.offsetHeight > 4000 ? (document.body.offsetHeight - 1050) : document.body.offsetHeight;
          let scroll = window.scrollY; 
          const val =   Math.floor(((scroll+200)* 100) / height);
  
          setDH(val) 
   
        });
        window.addEventListener("scroll",  (e)=> {
          setShowScrollItem(Math.floor(window.scrollY) > 500 ? true : false);
        });
    }


  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  
    useEffect(()=>{ 
      MoveFrame();
    }, []);

  return (
    <div>
      <a href='#' onClick={topFunction} className={ShowScrollItem ? "scroll-to-top show"  : "scroll-to-top"}><FaChevronUp /> 
         <div className={"fill"}  style={{height:`${DH}%`}}></div> 
      </a>  
    </div>
  )
}

export default ScrollToTop
