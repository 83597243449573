import React from 'react'
import ImageLoading from './ImageLoading';
import Translator from './I18n/Translator';

function HomeModules() {

  const Data  = [ 
      {
        icon:require("../Assets/Images/icons/1.png"),
        text1:`Estrutura `,    
        text2:`Totalmente Web`,    
        }, 
        {
        icon:require("../Assets/Images/icons/2.png"),
        text1:`Portal do Colaborador `,    
        text2:`com funções inovadoras`,    
      },   
      {
        icon:require("../Assets/Images/icons/3.png"),
        text1:`Vistas e Layout `,    
        text2:`inovadores`,    
        }, 
        {
        icon:require("../Assets/Images/icons/4.png"),
        text1:`Estrutura Hierárquica `,    
        text2:`Fluxos de Aprovação`,    
      },   
     {
        icon:require("../Assets/Images/icons/5.png"),
        text1:`Integração com qualquer ERP ou `,    
        text2:`Plataforma de Processamento Salarial `,    
        }, 
        {
        icon:require("../Assets/Images/icons/6.png"),
        text1:`Gestão de Recibos de Vencimento `,    
        text2:`e Declarações de Rendimentos`,    
     },          
    {
        icon:require("../Assets/Images/icons/7.7.png"),
        text1:`Suporte Técnico `,    
        text2:`Premium`,    
       }, 
       {
        icon:require("../Assets/Images/icons/8.8.png"),
        text1:`24 Módulos `,    
        text2:`Disponíveis`,    
     },     
        {
        icon:require("../Assets/Images/icons/10.10.png"),
        text1:`Harmonia perfeita entre App de `,    
        text2:`Gestão de Trabalho e Gestão de RH`,    
     },   
  ];  

 
  return (
    <div className='home-modules'>
        <div className="wrapper">
          <div className="center">
             <h1 className="title-2"><Translator path="home.modules.title" /></h1> 
          </div>
             <div className="content"> 
                {Data.map((info, index)=>(
                  <article key={index} className='d-flex'> 
                      <div className='d-flex info-box' key={index}>
                        <div className="icon"> 
                          <ImageLoading height={60} source={info.icon} /> 
                        </div>
                        <div className="text">
                            <p>{info.text1}<strong>{info.text2}</strong></p>
                        </div>
                      </div> 
                  </article>
                ))} 
             </div>
        </div>
    </div>
  )
}

export default HomeModules
