import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic"; 
import './i18n'

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider template={AlertTemplate} {...options}>
      <App />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
