import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageLoading from './ImageLoading';
import { TfiArrowTopRight } from "react-icons/tfi";
import { FiArrowRight } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Translator from './I18n/Translator';


function PerformanceSection() {
   const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      autoplay: true,
      speed: 4000,
      autoplaySpeed: 4000,
      slidesToShow: 2,
      slidesToScroll: 2
   };

   return (
      <section className='bg-section performance-section'>
         <div className="wrapper">
            <div className="content">
               <div className="title-info">
                  <h1 className="title-2"><Translator path="home.performance.title" /> </h1>
                  <p className='description-1'>
                     #<Translator path="home.footer.text0" />
                  </p>
               </div>
               <div className="boxes-area">
                  <article>
                     <div className="text-el">
                        <div><h1 className="title-2"><Translator path="home.performance.boxes.box1.title" /> </h1></div>
                        <Link to="/request_budget">
                           <div className="arrow"><FiArrowRight /></div>
                        </Link>
                     </div>
                     <p className="description-1"><Translator path="home.performance.boxes.box1.desc" /></p>
                     <div className="box"><ImageLoading height={300} source={"https://media.licdn.com/dms/image/D4D12AQG1FCdVRK3sFw/article-cover_image-shrink_600_2000/0/1675398103652?e=2147483647&v=beta&t=NKF2oP2u-Cr1QZXUAGtFdpstSelzNcTyXhNfdnVeoF8"} alt="" /> </div>
                  </article>
                  <article className='bg-main'>
                     <div className="text-el">
                        <div><h1 className="title-2"><Translator path="home.performance.boxes.box2.title" /></h1></div>
                        <Link to="/request_budget">
                           <div className="arrow"><FiArrowRight /></div>
                        </Link>
                     </div>
                     <p className="description-1"><Translator path="home.performance.boxes.box2.desc" /></p>
                     <div className="box"><ImageLoading height={300} source={"https://cdn.prod.website-files.com/6448bf6f064020ce1b2ca19d/659e6bf76febb36a0534e9d5_Exploring%20the%20Integration%20of%20Cloud-Based%20Machine%20Learning%20with%20Edge%20Devices.jpg"} alt="" /> </div>
                  </article>
                  <article>
                     <div className="text-el">
                        <div><h1 className="title-2"><Translator path="home.performance.boxes.box3.title" /></h1></div>
                        <Link to="/request_budget">
                           <div className="arrow"><FiArrowRight /></div>
                        </Link>
                     </div>
                     <p className="description-1"><Translator path="home.performance.boxes.box3.desc" /></p>
                     <div className="box"> <ImageLoading height={300} source={"https://4infra.com.br/wp-content/uploads/2023/10/seguranca-em-cloud-computing-e1698173973967.jpg"} alt="" /></div>
                  </article>
               </div>
            </div>
         </div>
      </section>
   )
}

export default PerformanceSection
