import React from 'react'
import ImageLoading from '../ImageLoading'
import { ImQuotesRight } from "react-icons/im";

function TextimonialExtra() {
  return (
    <div className='testimonial-frame d-none'>
      <div className="wrapper">
        <div className="content">
          <div className="text-area">
            <div className="block">
              <h2 className="title-3">A Equipa 2Smart fez um excelente trabalho Criando um software de gestão de
                Recursos Humanos simples e rapido.
              </h2>
              <div className="bg-main bx">
                <p className="description-2">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Mollitia accusantium assumenda 
                  alias, unde eos minus dolore sapiente explicabo, expedita magnam numquam soluta doloremque adipisci a.
                </p>
                <div className="quote"><ImQuotesRight /></div>
              </div>
              <div className="block-user">
                <div className="image">
                  <ImageLoading height={500} 
                  source={"https://static.vecteezy.com/system/resources/thumbnails/005/346/410/small_2x/close-up-portrait-of-smiling-handsome-young-caucasian-man-face-looking-at-camera-on-isolated-light-gray-studio-background-photo.jpg"}   />
                </div>
                <div className="txt">
                    <h3>Rafael Martins</h3>
                    <span>Colaborador na Bloom</span>
                  </div>
              </div>
            </div>
          </div>
          <div className="frame-area">
            <ImageLoading source={require("../../Assets/Images/fr1.png")} height={90} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TextimonialExtra
