import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ImageLoading from './ImageLoading';
import Marquee from "react-fast-marquee";
import Translator from './I18n/Translator';

function CustomersSection() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows:false,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll:4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
 };
 

  const Data = [
    {image:"https://www.santini.pt/wp-content/themes/s4ntini/images/santini-mainlogo.svg"},  
    {image:"https://casarelvas.pt/img/Logo_Intro-01.4b37a539.svg"},  
    {image:"https://www.leathernaturally.org/wp-content/uploads/2023/03/couroazul.png"},   
    {image:"https://statics.impresa.pt/php-assets/impresa/active/assets/gfx/logo_hq.png"},   
    {image:"https://i0.wp.com/www.zapping-tv.com/wp-content/uploads/2013/02/sic_2.jpg?fit=555%2C404&ssl=1"},   
    {image:"https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Expresso_newspaper_logo.svg/744px-Expresso_newspaper_logo.svg.png"},   
    {image:"https://sdistribution.impresa.pt/data/content/binaries/b58/207/7422461b-ba2f-49d1-a5bf-dcddad117f38/sicnot.svg"},   
    {image:"https://upload.wikimedia.org/wikipedia/commons/thumb/9/96/Intermarch%C3%A9_logo_2009_classic.svg/2560px-Intermarch%C3%A9_logo_2009_classic.svg.png"},   
    {image:"https://cm-condeixa.pt/img/logo.png"},   
    {image:"https://desentop.com/wp-content/uploads/2023/09/logo_desentop_RGB_positivo-2.svg"},   
    {image:"https://www.funchal.pt/wp-content/uploads/2023/09/0_WFx_mun.png"},   
    {image:"https://sa-formacao.pt/wp-content/uploads/2023/05/logo-vida-mar-resorts.webp"},  
    {image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNLwsWtWagT5D4-jYBv3uL0sDbk-PBsKUrXw&s"},   
    {image:"https://financor.pt/wp-content/uploads/2018/01/logo1.png"},   
    {image:"https://www.cm-mira.pt/sites/default/files/logo2.png"},   
    {image:"https://www.han.gov.cv/images/logotipo-versao-4.png"},   
    {image:require("../Assets/Images/customers/100_Montaditos.png")},   
  ];


  return (
    <section className='customer-section'>
      <div className="wrapper">
        <div className="content">
          <p className="title-4"><Translator path="home.clients_description" /> 
           <div className="mt-2"><strong className='title-4'>   Let's be Smarter together ?</strong> </div></p><br /><br />
          <Marquee speed={100}   >
             {Data.map((item, index)=>(
                <article  key={index}> 
                  <ImageLoading height={50} source={item.image} className="image-item" />
                </article>
             ))} 
          </Marquee> 
          <div id='point-2'></div>
          <div className="bar"></div>
        </div>
      </div>
    </section>
  )
}

export default CustomersSection
